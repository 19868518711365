<div class="call-details-content">

  <p *ngIf="!call"><em>Loading...</em></p>

  <div *ngIf="call">
    <br />
    <h3>Call Details</h3>
    <hr />
    <h4> Site:</h4>
    <table datatable [dtOptions]="dtOptions" class='table table-bordered  ' width="100%">
      <thead>
        <tr class="table-info">
          <th>Call Number</th>
          <th>Reported</th>
          <th>Customer</th>
          <th>Site</th>
          <th>Caller</th>
          <th>Caller Job Title </th>
          <th>Caller Telephone </th>
          <th>Caller Mobile </th>
          <th>Caller Email</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ call.callNumber }}</td>
          <td>{{ call.reported | date: 'dd/MM/yyyy HH:mm:ss' }}</td>
          <td>{{ call.title1 }}</td>
          <td>{{ call.siteName }}</td>
          <td>{{ call.caller }}</td>
          <td>{{ call.callerJobTitle }}</td>
          <td>{{ call.callerTelephone }}</td>
          <td>{{ call.callerMobile }}</td>
          <td>{{ call.calleremail }}</td>
        </tr>
      </tbody>
    </table>
    <br />
    <h4> Call Information:  </h4>

    <table datatable [dtOptions]="dtOptions1" class='table table-bordered  ' width="100%">
      <thead>
        <tr class="table-info">
          <th>Description</th>
          <th>Status</th>
          <th>Call Type</th>
          <th>System</th>
          <th>Assigned to</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>{{ call.description }}</td>
          <td> {{ call.cS_DESC }}</td>
          <td>{{ call.cT_DESC }}</td>
          <td>{{ call.cSys_DESC }}</td>
          <td>{{ call.displayName }}</td>
          <td *ngIf="!isSecurityGuard else notAdmin">
            <div class="btn-toolbar " role="toolbar">
              <div class="btn-group mr-2" role="group">
              </div>
              <div class="btn-group" role="group">
                <input type="file" accept="*" #file placeholder="Choose file for uploading to Azure Blob" (change)="azureUploadFile(file.files)" style="display:none;" multiple>
                <button class="btn btn-info" (click)="file.click()">Add Files</button>
              </div>
            </div>
          </td>
          <ng-template #notAdmin>
            <td>
              <div class="btn-toolbar " role="toolbar">
                <div class="btn-group mr-2" role="group">
                </div>
                <div class="btn-group" role="group">
                  <input type="file" accept="*" #file placeholder="Choose file for uploading to Azure Blob" (change)="azureUploadFile(file.files)" style="display:none;" multiple>
                  <button class="btn btn-info" (click)="file.click()">Add Files</button>
                </div>
              </div>
            </td>
          </ng-template>
        </tr>
      </tbody>
    </table>
    <div *ngIf="call.uploadedFiles?.length != 0">
      <h4>Files:</h4>
      <table datatable [dtOptions]="dtOptions2" class='table table-bordered  ' width="100%">
        <thead>
          <tr class="table-info">
            <th>Description</th>
            <th>Link to file</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of call.uploadedFiles">
            <td>{{ item.description }}</td>
            <td>
              <a href="{{item.link}}" target="_blank">Click here to see/download the <strong>{{item.description}}</strong> file</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div *ngIf="call.cS_DESC=='Closed' || call.cS_DESC=='Resolved'">
      <h4>Outcome:</h4>
      <table datatable [dtOptions]="dtOptions3" class='table table-bordered  ' width="100%">
        <thead>
          <tr class="table-info">
            <th>Solution</th>
            <th>Parts</th>
            <th>Resolution</th>
            <th>Job</th>
            <th>Responded</th>
            <th>Response Time</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td *ngIf="isSiteManager else otherUsers">
              <textarea class="form-control" rows="6" type="text" [(ngModel)]="call.solution" disabled></textarea>
            </td>
            <ng-template #otherUsers>

              <td *ngIf="call.calleremail == userEmail else notPersonalCall">
                <textarea class="form-control" rows="6" type="text" [(ngModel)]="call.solution" disabled></textarea>
              </td>
              <ng-template #notPersonalCall>
                <td>
                  {{call.solution}}
                </td>
              </ng-template>

            </ng-template>
            <td>{{ call.parts }}</td>
            <td>{{ call.resolution }}</td>
            <td>{{ call.job }}</td>
            <td>{{ call.responded | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td>{{ call.responseTime }}</td>
          </tr>
        </tbody>
      </table>

      <table datatable [dtOptions]="dtOptions4" class='table table-bordered  ' width="100%">
        <thead>
          <tr class="table-info">
            <th>Completed</th>
            <th>Call Duration</th>
            <th>Final Close</th>
            <th>Rating</th>
            <th>Resolved Version</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ call.completed | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
            <td>{{ call.callDuration }}</td>
            <td>{{ call.finalClose }}</td>
            <td>{{ call.cR_DESC }}</td>
            <td>{{ call.resolvedVersion }}</td>
          </tr>
        </tbody>
      </table>

    </div>
    <div class="row">
      <div class="col-md-10">
        <button class="btn btn-info" (click)="backClicked()">Back</button>

      </div>
      <div class="col-md-2">
        <div *ngIf="isSiteManager else otherUsers">
          <div class="btn-holder" >
            <div>
              <button class="btn btn-danger" (click)="cancel()" *ngIf="call.cS_DESC == 'New'">Cancel Call</button>
              <button class="btn btn-success" (click)="save()">Save</button>
            </div>
          </div>
        </div>
        <ng-template #otherUsers>
          <div>
            <div class="btn-holder" >
              <button class="btn btn-danger" (click)="cancel()" *ngIf="call.cS_DESC == 'New' && call.calleremail == userEmail">Cancel Call</button>
              <button class="btn btn-success" (click)="save()">Save</button>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
