
<nav class="navbar navbar-expand-md navbar-toggleable-md navbar-dark fixed-left" id="filter_navbar" #fixednav>
  <button class="navbar-toggler bg-primary" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation"
          [attr.aria-expanded]="isExpanded" (click)="toggle()">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="navbar-collapse collapse navbar-filter" [ngClass]='{"show": isExpanded}'>
    <div class="row" id="calendars">
      <div class="">
        <div class="calendar-col">
          <label>Select Date</label>
          <!-----start calendar startDate----->

          <form class="form-inline">
            <div class="form-group">
              <div class="input-group">
                <input class="form-control" placeholder="Select Date"
                        name="dp" [(ngModel)]="fromDate" ngbDatepicker #_d="ngbDatepicker" (ngModelChange)="onDateSelection($event)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="_d.toggle()" type="button" style="background-color:wheat"><i class="fa fa-calendar"></i></button>
                </div>
              </div>
            </div>
          </form>

          <!-----end calendar startDate----->
        </div>
        <div class="calendar-col">
          <label>Select End Date</label>
          <!-----start calendar startDate----->

          <form class="form-inline">
            <div class="form-group">
              <div class="input-group">
                <input class="form-control" placeholder="Select End Date"
                       name="dp" [(ngModel)]="toDate" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="onDateSelection($event)">
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button" style="background-color:wheat"><i class="fa fa-calendar"></i></button>
                </div>
              </div>
            </div>
          </form>
          <button (click)="onClearDateFilter()" class="btn" id="clear-filter-button">Clear Date Filter</button>
          <!-----end calendar startDate----->
        </div>
      </div>
    </div>

    <br />
    <div class="" id="periods">
      <label>Period filters</label> <br />
      <button type="button" class="btn btn-secondary btn-sm" [style.background-color]="isPeriodFilterButton1Selected ? '#3fae29' : '#6c757d'" (click)="filterByThisMonth();changePeriodFilterButton1()">This month</button>
      <button type="button" class="btn btn-secondary btn-sm"  [style.background-color]="isPeriodFilterButton2Selected ? '#3fae29' : '#6c757d'" (click)="filterByLastMonth();changePeriodFilterButton2()">Last month</button>
      <button type="button" class="btn btn-secondary btn-sm"  [style.background-color]="isPeriodFilterButton3Selected ? '#3fae29' : '#6c757d'" (click)="filterByThisYear();changePeriodFilterButton3()">This year</button>
      <button type="button" class="btn btn-secondary btn-sm"  [style.background-color]="isPeriodFilterButton4Selected ? '#3fae29' : '#6c757d'" (click)="filterByLastYear();changePeriodFilterButton4()">Last year</button>
    </div>
    <!-- end get Call Types-->
    <div class="" id="sla-nav">
      <label>SLA</label> <br />
      <button type="button" class="btn btn-secondary btn-sm" [style.background-color]="isSLAButton3Selected ? '#3fae29' : '#6c757d'" (click)="allSLA();changeSLAButton3()">All</button>
      <button type="button" class="btn btn-secondary btn-sm" [style.background-color]="isSLAButton1Selected ? '#3fae29' : '#6c757d'" (click)="isWithinSLA();changeSLAButton1()">{{withinSLA}}</button>
      <button type="button" class="btn btn-secondary btn-sm"  [style.background-color]="isSLAButton2Selected ? '#3fae29' : '#6c757d'" (click)="isOverSLA();changeSLAButton2()">{{overSLA}}</button>
    </div>
    <!--*******************************-->
    <div class="navbar-wrapper">
      <br />
      <label>Call Types:</label>
      <ng-multiselect-dropdown [placeholder]="'Select Call Types'"
                                [data]="callTypeList"
                                [(ngModel)]="selectedCallTypes"
                                [settings]="dropdownSettings"
                                (onSelect)="onItemSelect($event)"
                                (onSelectAll)="onSelectAll($event)"
                                (onDeSelect)="onItemDeSelect($event)"
                               (onDeSelectAll)="onItemDeSelectAllCallTypes($event)"
                               (onDropDownClose)="onDropDownClose()">
      </ng-multiselect-dropdown>

    </div>
    <!--*******************************-->
    <!--*******************************-->
    <div class="navbar-wrapper">
      <br />
      <label>Sites:</label>
      <ng-multiselect-dropdown [placeholder]="'Select Sites'"
                                [data]="siteList"
                                [(ngModel)]="selectedSites"
                                [settings]="dropdownSettingsSites"
                                (onSelect)="onItemSelectSite($event)"
                                (onSelectAll)="onSelectAllSite($event)"
                                (onDeSelect)="onItemDeSelectSite($event)"
                               (onDeSelectAll)="onItemDeSelectAllSite($event)"
                               (onDropDownClose)="onDropDownCloseSites()">
      </ng-multiselect-dropdown>

    </div>
    <!--*******************************-->
  </div>

</nav>
<div class="container" id="charts-content">
  <!--<hr>-->
  <div class="row">
    <div class="col-sm-10">
      <br />
      <h3>Reporting Dashboard</h3>
    </div>
    <div class="col-sm-2">
    </div>
  </div>

  <div class="row">
    <div class="col-md-6 col-12">
      <!----------------start chart _OpenCalls---------------->

      <hr />
      <u>
        <p class="text-center">Open Calls by Call Type </p>
      </u>

      <div>
        <div>
          <div>
            <div style="display: block" id="annually">
              <canvas baseChart [datasets]="barChartData_OpenCalls" [labels]="barChartLabels_OpenCalls" [options]="barChartOptions_OpenCalls"
                      [legend]="barChartLegend_OpenCalls" [chartType]="barChartType_OpenCalls" (chartHover)="chartHovered_OpenCalls($event)"
                      (chartClick)="chartClicked_OpenCalls($event)"></canvas>
            </div>
          </div>
        </div>
      </div>
      <br />
      <!----------------end chart _OpenCalls---------------->

    </div>
    <div class="col-md-6 col-12">

      <!-----start pie chart----->
      <div >
        <hr />
        <u>

          <p class="text-center">Calls By System Type</p>
          <!--<p class="text-center">SYSTEM AT FAULT {{startMonth}}-{{endMonth}} {{fromDate?.year}}</p>-->
        </u>
        <div style="display: block;">
          <canvas baseChart
                  [data]="pieChartData"
                  [labels]="pieChartLabels"
                  [chartType]="pieChartType"
                  [options]="pieChartOptions"
                  [plugins]="pieChartPlugins"
                  [colors]="pieChartColors"
                  [legend]="pieChartLegend"></canvas>
        </div>
      </div>

    </div>
  </div>
  <!----------------start chart for each site Monthly by year---------------->
  <hr />
  <u>

    <p class="text-center">Total Number of calls per month</p>
  </u>
  <div>
    <div>
      <div>
        <div style="display: block">
          <canvas baseChart [datasets]="barChartDataSites_Monthly" [labels]="barChartLabelsSites_Monthly" [options]="barChartOptionsSites_Monthly"
                  [legend]="barChartLegendSites_Monthly" [chartType]="barChartTypeSites_Monthly" (chartHover)="chartHoveredSites_Monthly($event)"
                  (chartClick)="chartClickedSites_Monthly($event)"></canvas>
        </div>
      </div>
    </div>
  </div>

  <!----------------end chart for each site Monthly by year---------------->

      <hr />
  <div class="row">
    <div class="col-md-6 col-12">
      <!----------------start chart annual by sites---------------->
      <u>

        <p class="text-center">Calls By Site</p>
      </u>

      <div>
        <div>
          <div>
            <div style="display: block" id="annually">
              <canvas baseChart [datasets]="barChartData_Annually" [labels]="barChartLabels_Annually" [options]="barChartOptions_Annually"
                      [legend]="barChartLegend_Annually" [chartType]="barChartType_Annually" (chartHover)="chartHovered_Annually($event)"
                      (chartClick)="chartClicked_Annually($event)"></canvas>
            </div>
          </div>
        </div>
      </div>
      <br />
      <!----------------end chart annual by sites---------------->
    </div>
    <div class="col-md-6 col-12">

      <p class="text-center">
        <u>
          Calls By Call Type
        </u>
      </p>
      <div>
        <div>
          <div>
            <div style="display: block">
              <canvas baseChart [datasets]="barChartData_CallsByCallType" [labels]="barChartLabels_CallsByCallType" [options]="barChartOptions_CallsByCallType"
                      [legend]="barChartLegend_CallsByCallType" [chartType]="barChartType_CallsByCallType" (chartHover)="chartHovered_CallsByCallType($event)"
                      (chartClick)="chartClicked_CallsByCallType($event)"></canvas>
            </div>
            <br />
          </div>
        </div>
      </div>
      <hr />
    </div>
  </div>
</div>
