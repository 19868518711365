import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './_services';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
    title = 'Call Manager';

    constructor(
        private authenticationService: AuthenticationService
    ) { }

    ngOnInit() {
        const msalAccount = this.authenticationService.loggedInAccount;
        if (msalAccount) {
            const currentUser = this.authenticationService.currentUserValue;
            if (currentUser) {
                console.info("App.Component: USER", currentUser);
            } else {
                this.authenticationService.loadUserRole();
            }
        } else {
            this.authenticationService.manageUserLoginStatus();
        }
    }

}
