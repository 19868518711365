import { HttpClient, HttpEventType } from '@angular/common/http';
import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from "@angular/router";
import { MsalService } from '@azure/msal-angular';
import { ToastrService } from 'ngx-toastr';
import { first } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { ClientDataModel } from '../_models';
import { AuthenticationService, UserService } from '../_services';

@Component({
    selector: 'app-log-call',
    templateUrl: './log-call.component.html',
    styleUrls: ['./log-call.component.css']
})

export class LogCallComponent implements OnInit {
    userInfo: any;
    public progress: number;
    public message: string;
    callForm: UntypedFormGroup;
    loading = false;
    registeredCallNumber: any;
    submitted = false;
    isCallsTableShowed: boolean = false;
    fileName: string = "Inspired Phase 2 dev notes.docx";
    azureBlobUrl: any;
    formData: any;
    baseUrl: string;
    callTypes: any;
    sitesNames: any;
    selectedCallType: number;
    selectedSite: number;
    selectedSiteName: string;
    callSystems: any;
    selectedCallSystem: number;
    uploadedFiles: any[] = [];
    @Output() public onUploadFinished = new EventEmitter();

    constructor(
        private http: HttpClient,
        private _DomSanitizationService: DomSanitizer,
        private authenticationService: AuthenticationService,
        private formBuilder: UntypedFormBuilder,
        private msalSvc: MsalService,
        private userService: UserService,
        @Inject('BASE_URL') baseUrl: string,
        private toastr: ToastrService,
        private router: Router
    ) {
        this.baseUrl = baseUrl;
        this.callForm = this.formBuilder.group({
            description: ['', Validators.required],
            callType: '',
            system: '',
            //callNumber: '',
            //reported: '',
            customer: '',
            newCallUrl: this.baseUrl,
            site: '',
            caller: '',
            callerJobTitle: '',
            callerTelephone: ['', [Validators.required, Validators.pattern("[\+]?[0-9 ]{10,16}")]],
            callerMobile: ['', [Validators.required, Validators.pattern("[\+]?[0-9 ]{10,16}")]],
            callerEmail: '',
            uploadedFiles: formBuilder.array([''])
        });
    }

    public ngOnInit() { 
        this.userService.getUserDetails().pipe(first())
            .subscribe(data => {
                this.userInfo = data;
                this.callForm.controls['callerMobile'].setValue(this.userInfo.callerMobile);
                this.callForm.controls['callerTelephone'].setValue(this.userInfo.callerTelephone);
                this.callForm.controls['callerEmail'].setValue(this.userInfo.username);
                this.callForm.controls['callerJobTitle'].setValue(this.userInfo.callerJobTitle);
                this.callForm.controls['caller'].setValue(this.userInfo.displayName);

                this.get_UserSites(this.userInfo.username, this.userInfo.customer);

                this.getCustomerCallTypes(this.userInfo).subscribe(
                    data => {
                        this.callTypes = data;
                        this.selectedCallType = this.callTypes[0].idCallTypes;
                    },
                    error => console.error(error));
            });

        this.getCallSystems();
    }

    get UserName() {
        return this.userInfo;
    }

    get f() { return this.callForm.controls; }

    getCustomerCallTypes(userInfo: any) {
        let data = new ClientDataModel();
        data.customer = userInfo.customer;
        data.email = userInfo.username;
        return this.http.post(this.baseUrl + `api/calls/GetClientCallTypes`, data)
    }

    get_UserSites(email : string, customerId: number) {
        this.http.get<any>(`${this.baseUrl}api/calls/getSitesOfUserByCustomer/${customerId}`)
            .subscribe(
                data => {
                    this.sitesNames = data;
                    this.selectedSite = this.sitesNames[0].siteID;
                }
            )
    }

    getCallSystems() {
        this.http.get(this.baseUrl + `api/calls/GetCallSystems`)
            .subscribe(
                data => {
                    this.callSystems = data;
                    this.selectedCallSystem = 2;
                },
                error => console.error(error));
    }
    onSubmit() {
        this.submitted = true;

        // stop here if form is invalid
        if (this.callForm.invalid) {
            return;
        }
        if (this.sitesNames.length == 1) {
            this.selectedSite = this.sitesNames[0].siteID;
        }
        this.callForm.value.callType = this.selectedCallType;
        this.callForm.value.customer = this.userInfo.customer;
        this.callForm.value.site = this.selectedSite;
        this.callForm.value.system = this.selectedCallSystem;
        this.callForm.value.uploadedFiles = this.uploadedFiles;
        this.createCall(this.callForm.value);
    }

    showCalls() {
        if (this.isCallsTableShowed) {
            this.isCallsTableShowed = false;
        }
        else {
            this.isCallsTableShowed = true;
        }
    }

    createCall(data: any) {
        var t = this.userInfo;
        this.http.post<any>(`${this.baseUrl}api/calls/CreateCall`, data)
            .subscribe(result => {
                this.registeredCallNumber = result.callNumber;
                Swal.queue([{
                    title: `Thank you for submitting a call. This is your Call Number: ${this.registeredCallNumber}`,
                    confirmButtonText: 'OK',
                    confirmButtonColor: '#3fae29',
                    showLoaderOnConfirm: true,
                    allowEscapeKey: false,
                    allowOutsideClick: false,
                    preConfirm: () => {
                        this.router.navigate(['/call-details', this.registeredCallNumber])
                        return null;
                    }
                }])
            }, error => {
                this.toastr.error(error);
            });
    }

    public uploadFile = (files) => {
        this.message = null;
        this.progress = 0;
        if (files.length === 0) {
            return;
        }

        let filesToUpload: File[] = files;
        const formData = new FormData();

        Array.from(filesToUpload).map((file, index) => {
            this.fileName = file.name;
            return formData.append('file' + index, file, file.name);
        });

        this.http.post('api/SampleData/create', formData, { reportProgress: true, observe: 'events' })
            .subscribe(event => {
                if (event.type === HttpEventType.UploadProgress)
                    this.progress = Math.round(100 * event.loaded / event.total);
                else if (event.type === HttpEventType.Response) {
                    this.message = event.body.toString();
                    this.onUploadFinished.emit(event.body);
                }
            });
    }
    //azure file upload
    public azureUploadFile = (files) => {
        let filesToUpload: File[] = this.fileExtensionCheck(files).slice();
        if(filesToUpload.length === 0){
            return;
        }
        const formData = new FormData();

        Array.from(filesToUpload).map((file, index) => {
            this.fileName = file.name;
            return formData.append('file' + index, file, file.name);
        });
        this.toastr.success("Files are added");
        this.http.post<any>(`${this.baseUrl}api/calls/UploadFiles`, formData)
            .subscribe(result => {
                this.uploadedFiles = result;
            },
                error => {
                    this.toastr.error("Error uploading the file(s), please try again later")
                    console.error(error)
                });
    }

    // This check is done backend too
    public fileExtensionCheck(filesToUpload: File[]): File[] {
        var filesToReturn: File[] = [];
        const extReject: string[] = ['.acc', '.ade', '.adp ', '.asp', '.bas', '.bat', '.ccs', '.chm', '.class', '.cmd', '.com', '.cpl', '.crt', '.dll', '.dmg', '.docm', '.dotm', '.drv', '.exe', '.grp', '.hlp', '.hta', '.htx', '.ink', '.ins', '.isp', '.jar', '.je', '.js', ' .js', '.jse', '.ldb', '.lnk', '.mad', '.mda', '.mdb', '.mde', '.mdz', '.msc', '.msh', '.msh1', '.msh1xml', '.msh2', '.msh2xml ', '.mshxml', '.msi', '.msp', '.mst', '.ocx', '.ovl', '.pcd', '.php', '.php3', '.pif', '.potm', '.ppam', '.ppsm', '.pptm', '.ps1', '.ps1xml', '.ps2', '.ps2xml', '.psc1', ' .psc2', '.reg', '.sbs', '.scf', '.scr', '.sct', '.shb', '.shd', '.shs', '.sldm', '.snp', '.sys', '.vb', '.vba', '.vbe', '.vbs', '.vdl', '.vxd', '.ws', '.wsc', '.wsf', '.wsh', '.wst', '.xlam', '.xlsm', '.xltm'];

        for (var file of filesToUpload) {
            var fileName = file.name

            var extension = fileName.substring(fileName.lastIndexOf('.'));
            if (extReject.includes(extension))
            {
                this.toastr.error(`Could not allow the upload of ${fileName}`, `Content Upload Policy`);
            }
            else 
            {
                filesToReturn.push(file)
            }
        }
        return filesToReturn
    }
}
