import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { AuthenticationService } from '../_services';
import { RolesService } from '../_services/roles.service';
import { User } from '../_models/user';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})

export class NavMenuComponent {
  isLoggedIn: boolean;
  userAccount: User;
  isExpanded: boolean = false;
 
  constructor(
    private msalSvc: MsalService,
    private authenticationService: AuthenticationService,
    public roleService: RolesService,    
  ) {
    this.isLoggedIn = (this.authenticationService.currentUserValue != null);

    this.authenticationService.currentUser$.subscribe(
      (user) => {
        this.userAccount = user;
        console.info('NAV - USER ACCOUNT', user);
      }
    )

    this.authenticationService.loggedInUser$.subscribe(
      (result) => {
        this.isLoggedIn = result;
        console.log('NAV - LOGGED IN', result);
      }
    )
    
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  logOut() {
    this.authenticationService.doLogout();    
  }

  logIn() {
    this.msalSvc.loginRedirect();
  }

}
