import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService, TokenService } from '../_services';

@Injectable()
export class AuthGuard implements CanActivate {
    _tokenservice: TokenService;
    constructor(private router: Router,
        private authenticationService: AuthenticationService,
        tokenservice: TokenService
    )
    {
        this._tokenservice = tokenservice;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        //console.info("AuthGuard canActivate" + JSON.stringify(currentUser));
        if (currentUser) {
            // check if route is restricted by role
            if (route.data.roles && route.data.roles.indexOf(this._tokenservice.userRole) === -1) {
                // role not authorised so redirect to home page
                this.router.navigate(['/denied']);
                return false;
            }
            // authorised so return true
            //console.info("authorised so return true");
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/denied']);
        //console.info("NOTauthorised so return false");
        return false;
    }
}
