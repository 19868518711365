export class CallTypeModel {
    callTypeID: number;
    callTypeName: string;
}

export class SiteModel {
    siteID: number;
    siteName: string;
}

export enum Period {
    ThisMonth = 1,
    LastMonth,
    ThisYear,
    LastYear
}

export enum SLA {
    WithinSLA = 1,
    OverSLA
}

export class FilterReportModel {
    email: string;
    startDate: string;
    endDate: string;
    period: Period;
    sla: SLA;
    callTypeList: string;
    siteList: string;
}


export class DropDownListener {
    private clickCount: number;
    constructor() {
        this.setDefaultClickCount();
    }

    public setDefaultClickCount() {
        this.clickCount = 0;
    }

    public ifItemsWereSelected(): boolean {
        return this.clickCount != 0;
    }
    public invokeDropDownClickCount() {
        this.clickCount++;
    }
}
