export class FilterData {
    email: string;
    selectedDate: string;
    sites: any[];
    customers: any[];
    callTypes: any[];
    statuses: any[];
    month: any;
    year: any;
    startYear: any;
    endYear: any;
    startMonth: any;
    endMonth: any;
    fromYear: any;
    fromMonth: any;
    fromDay: any;
    toYear: any;
    toMonth: any;
    toDay: any;
    isRange: any;
    sLA: any[];
    sla: string[];
}
