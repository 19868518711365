import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, Inject, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate, NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { RandomColor } from 'angular-randomcolor';
import { ChartDataSets, ChartOptions, ChartType } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import 'chartjs-plugin-labels';
import { Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip, SingleDataSet } from 'ng2-charts';
import { ToastrService } from 'ngx-toastr';
import { fromEvent } from 'rxjs';
import { FilterData } from '../_models/filterData';
import { Month } from '../_models/month';
import { CallTypeModel, DropDownListener, FilterReportModel, Period, SiteModel, SLA } from '../_models/reportModels';
import { TokenService } from '../_services';

@Component({
    selector: 'app-reporting-component',
    templateUrl: './reporting.component.html',
    styleUrls: ['./reporting.component.css']
})

export class ReportingComponent implements OnInit {
    @ViewChild('fixednav', { read: ElementRef }) public fixedNav: ElementRef;
    callsCount: any[] = [];
    sitesNames: UserSites[] = [];
    callsChartByYear: any[] = [];
    date: Date;
    callTypeList: CallTypeModel[];
    selectedCallTypes: CallTypeModel[];
    selectedCallType: any = "Standard";
    period: Period;
    slaID: SLA;
    sla: any = "within SLA";
    slaArray: any[] = ["within SLA", "Over SLA"];
    withinSLA: any = "Within SLA";
    overSLA: any = "Over SLA";
    displaySLA: any = "Within SLA";
    _pieChartDataArray: number[] = [];
    hoveredDate: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;
    _baseUrl: any;
    _calendar: any;
    sites: any[] = [];
    siteList: SiteModel[];
    selectedSites: any;
    dropdownList = [];
    selectedItems = [];
    dropdownSettings = {};
    dropdownSettingsSites = {};
    isExpanded = false;
    filterData: FilterData;
    isRangeSelected: boolean;
    _startYear: any;
    _endYear: any;
    _startMonth: any;
    _endMonth: any;
    isPeriodFilterButton1Selected: boolean;
    isPeriodFilterButton2Selected: boolean;
    isPeriodFilterButton3Selected: boolean;
    isPeriodFilterButton4Selected: boolean;
    isSLAButton1Selected: boolean;
    isSLAButton2Selected: boolean;
    isSLAButton3Selected: boolean;
    _tokenservice: TokenService;
    scrollPosition: any;
    callTypesDropDownListaner: DropDownListener;
    sitesDropDownListaner: DropDownListener;

    collapse() {
        this.isExpanded = false;
    }

    toggle() {
        this.isExpanded = !this.isExpanded;
    }

    constructor(
        public calendar: NgbCalendar,
        private config: NgbDatepickerConfig,
        private http: HttpClient,
        private renderer: Renderer2,
        @Inject('BASE_URL') baseUrl: string,
        private toastr: ToastrService,
        tokenservice: TokenService
    ) {
        this.slaID = null;
        this.period = Period.ThisYear;
        this._startMonth = 1;
        this._startYear = calendar.getToday().year;
        this._endMonth = 12;
        this._endYear = calendar.getToday().year;
        this.isPeriodFilterButton3Selected = true;
        this.isSLAButton3Selected = true;
        this._baseUrl = baseUrl;
        this._tokenservice = tokenservice;

        monkeyPatchChartJsTooltip();
        monkeyPatchChartJsLegend();


        this.get_CallTypes_Sites().subscribe(
            response => {
                // ┌─────────────────────────────────────────────────────────────────┐
                // │				       	Response model   							                   │
                // ├─────────────────────────────────────────────────────────────────┤
                // │ callTypes{ Array[{callTypeID, callTypeName}, {...}, {...}]}     │
                // │ userSites{ Array[{siteID, siteName, isChecked}, {...}, {...}]}  │
                // └─────────────────────────────────────────────────────────────────┘
                this.callTypeList = response.callTypes;
                this.selectedCallTypes = this.callTypeList;
                this.siteList = response.userSites;
                this.selectedSites = this.siteList;
                this.applyFilters(this._tokenservice.userEmail, this.period, this.slaID, "Loading ...", "Loaded!");
                this.callTypesDropDownListaner = new DropDownListener();
                this.sitesDropDownListaner = new DropDownListener();
            }
        );
    }

    ngOnInit() {
        //--------------start ng-multiselect-dropdown ---------------
        this.dropdownSettings = {
            singleSelection: false,
            idField: 'callTypeID',
            textField: 'callTypeName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: false
        };

        this.dropdownSettingsSites = {
            singleSelection: false,
            idField: 'siteID',
            textField: 'siteName',
            selectAllText: 'Select All',
            unSelectAllText: 'UnSelect All',
            itemsShowLimit: 3,
            allowSearchFilter: false
        };

        fromEvent(window, 'scroll').subscribe(e => {
            window.pageYOffset > 143 ?
                this.renderer.addClass(this.fixedNav.nativeElement, 'navbar-fixed') :
                this.renderer.removeClass(this.fixedNav.nativeElement, 'navbar-fixed')
        })
    }

    changePeriodFilterButton1() {
        this.isPeriodFilterButton1Selected = true;
        this.isPeriodFilterButton2Selected = false;
        this.isPeriodFilterButton3Selected = false;
        this.isPeriodFilterButton4Selected = false;
    }
    changePeriodFilterButton2() {
        this.isPeriodFilterButton1Selected = false;
        this.isPeriodFilterButton2Selected = true;
        this.isPeriodFilterButton3Selected = false;
        this.isPeriodFilterButton4Selected = false;

    }
    changePeriodFilterButton3() {
        this.isPeriodFilterButton1Selected = false;
        this.isPeriodFilterButton2Selected = false;
        this.isPeriodFilterButton3Selected = true;
        this.isPeriodFilterButton4Selected = false;

    }
    changePeriodFilterButton4() {
        this.isPeriodFilterButton1Selected = false;
        this.isPeriodFilterButton2Selected = false;
        this.isPeriodFilterButton3Selected = false;
        this.isPeriodFilterButton4Selected = true;

    }

    changeSLAButton1() {
        this.isSLAButton1Selected = true;
        this.isSLAButton2Selected = false;
        this.isSLAButton3Selected = false;
    }

    changeSLAButton2() {
        this.isSLAButton1Selected = false;
        this.isSLAButton2Selected = true;
        this.isSLAButton3Selected = false;
    }

    changeSLAButton3() {
        this.isSLAButton1Selected = false;
        this.isSLAButton2Selected = false;
        this.isSLAButton3Selected = true;
    }

    onItemSelect(item: any) {
        this.callTypesDropDownListaner.invokeDropDownClickCount();

    }

    onSelectAll(items: any) {
        this.callTypesDropDownListaner.invokeDropDownClickCount();

        this.selectedCallTypes = this.callTypeList;
    }

    onItemDeSelect(item: any) {
        this.callTypesDropDownListaner.invokeDropDownClickCount();
    }

    onItemDeSelectAllCallTypes(item: any) {
        this.callTypesDropDownListaner.invokeDropDownClickCount();
        this.selectedCallTypes = [];
    }

    onDropDownClose() {
        if (this.callTypesDropDownListaner && this.callTypesDropDownListaner.ifItemsWereSelected()) {
            this.applyFilters(this._tokenservice.userEmail, this.period, this.slaID, `Filtering ...`, `Filtered by "Call Types"`);
            this.callTypesDropDownListaner.setDefaultClickCount();
        }
    }

    onDropDownCloseSites() {
        if (this.sitesDropDownListaner && this.sitesDropDownListaner.ifItemsWereSelected()) {
            this.applyFilters(this._tokenservice.userEmail, this.period, this.slaID, `Filtering ...`, `Filtered by "Sites"`);
            this.sitesDropDownListaner.setDefaultClickCount();
        }
    }

    onItemSelectSite(item: any) {
        this.sitesDropDownListaner.invokeDropDownClickCount();
    }
    onSelectAllSite(items: any) {
        this.selectedSites = this.siteList;
        this.sitesDropDownListaner.invokeDropDownClickCount();
    }
    onItemDeSelectSite(item: any) {
        this.sitesDropDownListaner.invokeDropDownClickCount();
    }

    onItemDeSelectAllSite(item: any) {
        this.selectedSites = [];
        this.sitesDropDownListaner.invokeDropDownClickCount();
    }
    //--------------end ng-multiselect-dropdown ---------------

    get email() {
        return this._tokenservice.userEmail;
    }

    get startMonth() {
        return Month[this.fromDate.month];
    }

    get endMonth() {
        return Month[this.toDate.month];
    }
    //Total Number of calls per month
    get_MonthlyChartData(email: string, startYear: any, endYear: any, startMonth: any, endMonth: any, siteNames: string[], callTypes: string[], sla: string[]) {
        this.filterData = new FilterData();
        this.filterData.email = email;
        this.filterData.sites = this.selectedSites;
        this.filterData.startYear = startYear;
        this.filterData.endYear = endYear;
        this.filterData.startMonth = startMonth;
        this.filterData.endMonth = endMonth;
        this.filterData.sites = siteNames;
        this.filterData.callTypes = callTypes;
        this.filterData.sla = sla;

        this.http.post<any>(`${this._baseUrl}api/calls/GetTotalNumberOfCallsPerMonth`, this.filterData)
            .subscribe(
                data => {
                    this.barChartDataSites_Monthly = [];
                    this.barChartLabelsSites_Monthly = [];
                    this.barChartDataSites_Monthly = [{ data: data.counts, label: `` }]
                    this.barChartLabelsSites_Monthly = data.callMonthes
                },
                error => console.error(error));
    }

    get_UserSites(email: string) {
        this.http.get<any>(`${this._baseUrl}api/calls/getSitesOfUser`)
            .subscribe(
                data => {
                    this.sitesNames = data;
                    this.sites = this.sitesNames.map(x => {
                        return x.siteName;
                    })
                    this.selectedSites = this.sites;
                    this.get_CallsByCallTypeData(this._tokenservice.userEmail, this._startYear, this._endYear, this._startMonth, this._endMonth, this.selectedSites.map(x => { return x.siteName }), this.selectedCallTypes.map(x => { return x.callTypeName }), this.slaArray);
                    this.get_FaultPieChartData(this._tokenservice.userEmail, this._startYear, this._endYear, this._startMonth, this._endMonth, this.selectedSites.map(x => { return x.siteName }), this.selectedCallTypes.map(x => { return x.callTypeName }), this.slaArray)
                    this.filterAnnualChart(this._tokenservice.userEmail, this._startYear, this._startYear, this.selectedSites.map(x => { return x.siteName }), this.selectedCallTypes.map(x => { return x.callTypeName }), this._startMonth, this._endMonth, this.slaArray);
                    this.get_MonthlyChartData(this._tokenservice.userEmail, this._startYear, this._endYear, this._startMonth, this._endMonth, this.selectedSites.map(x => { return x.siteName }), this.selectedCallTypes.map(x => { return x.callTypeName }), this.slaArray);
                    this.get_OpenCallChartData(this._tokenservice.userEmail, this._startYear, this._endYear, this.selectedSites.map(x => { return x.siteName }), this.selectedCallTypes.map(x => { return x.callTypeName }), this.slaArray, this._startMonth, this._endMonth);
                },
                error => console.error(error));
    }

    get selectedSitesOnlyNames() {
        return this.sitesNames.map(s => s.siteName)
    }

    get_CallsByCallTypeData(email: string, startYear: any, endYear: any, startMonth: any, endMonth: any, siteNames: string[], callTypes: string[], sla: string[]) {
        this.filterData = new FilterData();
        this.filterData.email = email;
        this.filterData.sites = siteNames;
        this.filterData.startYear = startYear;
        this.filterData.endYear = endYear;
        this.filterData.startMonth = startMonth;
        this.filterData.endMonth = endMonth;
        this.filterData.sites = siteNames;
        this.filterData.callTypes = callTypes;
        this.filterData.sla = sla;

        this.http.post<CallsByCallType[]>(`${this._baseUrl}api/calls/GetCallsByCallTypeDataPOST`, this.filterData)
            .subscribe(
                data => {
                    this.barChartData_CallsByCallType = [];
                    this.barChartLabels_CallsByCallType = [];
                    var calls = [];
                    var colors = [];
                    for (var i = 0; i < data.length; i++) {
                        this.barChartLabels_CallsByCallType.push(data[i].callType)
                        calls.push(data[i].callsCount)
                        colors.push(`#${data[i].color}`)
                    }
                    this.barChartData_CallsByCallType = [{
                        data: calls,
                        label: "",
                        backgroundColor: colors
                    }]
                },
                error => console.error(error));
    }

    get_OpenCallChartData(email: string, startYear: any, endYear: any, siteNames: string[], callTypes: string[], sla: string[], startMonth: any, endMonth: any) {
        this.filterData = new FilterData();
        this.filterData.email = email;
        this.filterData.sites = this.selectedSites;
        this.filterData.startYear = startYear;
        this.filterData.endYear = endYear;
        this.filterData.startMonth = startMonth;
        this.filterData.endMonth = endMonth;
        this.filterData.sites = siteNames;
        this.filterData.callTypes = callTypes;
        this.filterData.sla = sla;

        this.http.post<any[]>(`${this._baseUrl}api/calls/GetOpenCallsPOST`, this.filterData)
            .subscribe(
                data => {
                    this.barChartLabels_OpenCalls = [];
                    this.barChartData_OpenCalls = [];
                    var calls = [];
                    var colors = [];
                    for (var i = 0; i < data.length; i++) {
                        this.barChartLabels_OpenCalls.push(data[i].callType)
                        calls.push(data[i].calls)
                        colors.push(`#${data[i].color}`)
                    }
                    this.barChartData_OpenCalls = [{
                        data: calls,
                        label: "",
                        backgroundColor: colors
                    }]
                },
                error => console.error(error));
    }

    get_CallTypesToObservable(email: string) {
        return this.http.get<any>(`${this._baseUrl}api/calls/GetCallTypes`)
    };

    onCallTypeSelected(data: any) {
        this.toastr.success(`Filtered ${data}`);
    }

    get_CallTypes_Sites() {
        return this.http.get<any>(`${this._baseUrl}api/calls/GetReportFilters`)
    };


    get_FaultPieChartData(email: string, startYear: any, endYear: any, startMonth: any, endMonth: any, siteNames: string[], callTypes: string[], sla: string[]) {
        this.filterData = new FilterData();
        this.filterData.email = email;
        this.filterData.sites = this.selectedSites;
        this.filterData.startYear = startYear;
        this.filterData.endYear = endYear;
        this.filterData.startMonth = startMonth;
        this.filterData.endMonth = endMonth;
        this.filterData.sites = siteNames;
        this.filterData.callTypes = callTypes;
        this.filterData.sla = sla;
        this.http.post<any[]>(`${this._baseUrl}api/calls/GetFaultsDataPOST`, this.filterData)
            .subscribe(
                data => {
                    this.pieChartLabels = [];
                    this.pieChartData = [];
                    this._pieChartDataArray = [];
                    for (var i = 0; i < data.length; i++) {
                        const newColor = RandomColor.generateColor();
                        this._pieChartDataArray.push(data[i].count);
                        this.pieChartLabels.push(data[i].callSystem);
                        this.pieChartColors[0].backgroundColor.push(newColor);
                    }
                    this.pieChartData = this._pieChartDataArray;
                },
                error => console.error(error));
    }

    filterAnnualChart(email: string, startYear: any, endYear: any, siteNames: string[], callTypes: string[], startMonth: any, endMonth: any, sla: string[]) {
        this.filterData = new FilterData();
        this.filterData.email = email;
        this.filterData.sites = this.selectedSites;
        this.filterData.startYear = startYear;
        this.filterData.endYear = endYear;
        this.filterData.startMonth = startMonth;
        this.filterData.endMonth = endMonth;
        this.filterData.sites = siteNames;
        this.filterData.callTypes = callTypes;
        this.filterData.sla = sla;

        this.http.post<ChartMonthlyData[]>(`${this._baseUrl}api/calls/GetAnnualDataPOST`, this.filterData)
            .subscribe(
                data => {
                    this.callsChartByYear = data;
                    this.barChartData_Annually = [];
                    this.barChartLabels_Annually = [];
                    for (var i = 0; i < data.length; i++) {
                        this.barChartData_Annually.push({ data: data[i].counts, label: `${data[i].siteName}` })
                        this.barChartLabels_Annually = data[i].years
                    }

                },
                error => console.error(error));
    }

    isWithinSLA() {
        this.slaID = SLA.WithinSLA
        this.sla = "within SLA";
        this.slaArray = ["within SLA"];

        this.displaySLA = this.withinSLA;
        this.applyFilters(this._tokenservice.userEmail, this.period, this.slaID, "Filtering ...", `Filtered "${this.displaySLA}"`);
    }

    isOverSLA() {
        this.slaID = SLA.OverSLA;
        this.sla = "over SLA";
        this.slaArray = ["over SLA"];
        this.displaySLA = this.overSLA;

        this.applyFilters(this._tokenservice.userEmail, this.period, this.slaID, "Filtering ...", `Filtered "${this.displaySLA}"`);

    }

    allSLA() {
        this.isRangeSelected = true;
        this.slaID = null;
        this.slaArray = ['within SLA', 'over SLA'];
        this.sla = "ALL"
        this.applyFilters(this._tokenservice.userEmail, this.period, this.slaID, "Filtering ...", `Filtered "ALL SLA"`);
    }

    selectPeriod() {
        this.toastr.info('Not ready!');
    }

    filterByThisMonth() {
        this._startMonth
        var thisMonth = this.calendar.getToday().month;
        var thisYear = this.calendar.getToday().year;
        this._startMonth = thisMonth;
        this._startYear = thisYear;
        this._endMonth = thisMonth;
        this._endYear = thisYear;
        this.fromDate = null;
        this.toDate = null;
        this.period = Period.ThisMonth;

        this.applyFilters(this._tokenservice.userEmail, Period.ThisMonth, this.slaID, `Filtering ...`, 'Filtered by "This Month"!');

    }

    filterByLastMonth() {
        var lastMonth = this.calendar.getToday().month - 1;
        var thisYear = this.calendar.getToday().year;
        this.fromDate = null;
        this.toDate = null;
        this._startMonth = lastMonth;
        this._startYear = thisYear;
        this._endMonth = lastMonth;
        this._endYear = thisYear;
        this.period = Period.ThisMonth;

        this.applyFilters(this._tokenservice.userEmail, Period.LastMonth, this.slaID, `Filtering ...`, 'Filtered by "Last Month"!');
    }

    filterByThisYear() {
        var thisYear = this.calendar.getToday().year;
        this.fromDate = null;
        this.toDate = null;
        this._startMonth = 1;
        this._startYear = thisYear;
        this._endMonth = 12;
        this._endYear = thisYear;
        this.period = Period.ThisYear;

        this.applyFilters(this._tokenservice.userEmail, Period.ThisYear, this.slaID, `Filtering ...`, 'Filtered by "This Year"!');

    }

    filterByLastYear() {
        var lastYear = this.calendar.getToday().year - 1;
        this.fromDate = null;
        this.toDate = null;
        this._startYear = lastYear;
        this.period = Period.LastYear;

        this.applyFilters(this._tokenservice.userEmail, Period.LastYear, this.slaID, `Filtering ...`, 'Filtered by "Last Year"!');

    }

    applyFilters(email: string, period: Period, sla: SLA, loadingToastrText: string, loadedToastrText: string, ) {
        let toast = this.toastr.info(loadingToastrText);


        //                                  Backend-Database Report Agreement Model:
        // ┌───────────────────────────────────────────────────┐┌───────────────────────────────────────────────────────────────────────────────────────────────────────────────┐
        // │				       	FilterReportModel							     ││  Description                                                                                                  │
        // ├───────────────────────────────────────────────────┤├───────────────────────────────────────────────────────────────────────────────────────────────────────────────┤
        // │email<string> = userEmail                          ││userEmail                                                                                                      │
        // │startDate<string> = null || "2019-12-01"           ││null when <Select Date> is not selected                                                                        │
        // │endDate<string> = null || "2019-12-25"             ││null when <Select End Date> is not selected                                                                    │
        // │period<int?> = null || 1 || 2 || 3 || 4            ││null when (startDate != null && endDate != null)                                                               │
        // │sla<int?> = null || 1 || 2                         ││All = null; WithinSLA = 1; Over SLA = 2                                                                        │
        // │callTypeList<string> = null || "1,2,3,4,5,6,7"     ││callTypeList contains CallTypeID in "id_1,id_2,id_3" format, callTypeList = null if all CallTypes is selected  │
        // │siteList<string> = null || "1,2,3,4,5,6,7"         ││siteList contains SiteID in "id_1,id_2,id_3" format, siteList = null if all sites is selected                  │
        // └───────────────────────────────────────────────────┘└───────────────────────────────────────────────────────────────────────────────────────────────────────────────┘
        let filterModel = new FilterReportModel();
        let callTypesListTemplate: string = null;
        let sitesListTemplate: string = null;
        if (this.callTypeList.length != this.selectedCallTypes.length)
            callTypesListTemplate = this.selectedCallTypes.map(x => { return x.callTypeID }).join(",");

        if (this.siteList.length != this.selectedSites.length)
            sitesListTemplate = this.selectedSites.map(x => { return x.siteID }).join(",");

        filterModel.email = email;
        if (this.fromDate != null) {
            filterModel.startDate = this.fromDate.year + "-" + this.fromDate.month + "-" + this.fromDate.day;
            filterModel.endDate = this.toDate.year + "-" + this.toDate.month + "-" + this.toDate.day;
        } else {
            filterModel.startDate = null;
            filterModel.endDate = null;
        }
        filterModel.period = period;
        filterModel.sla = sla;
        filterModel.callTypeList = callTypesListTemplate;
        filterModel.siteList = sitesListTemplate;

        this.http.post<any>(`${this._baseUrl}api/calls/GetReportDataSets`, filterModel).subscribe(
            response => {
                let openCallsByCallTypeData: any = response.openCallsByCallType;
                let callsBySystemTypeData: any = response.callBySystemType;
                let totalNumberOfCallsPerMonthData: any = response.monthlyCallStatistic;
                let CallsBySiteData: any = response.callsBySite;
                let CallsByCallTypeData: any = response.callsByCallType;

                //Open Calls by Call Type
                this.barChartLabels_OpenCalls = [];
                this.barChartData_OpenCalls = [];
                var calls = [];
                var colors = [];
                for (var i = 0; i < openCallsByCallTypeData.length; i++) {
                    this.barChartLabels_OpenCalls.push(openCallsByCallTypeData[i].callType)
                    calls.push(openCallsByCallTypeData[i].callsCount)
                    colors.push(`#${openCallsByCallTypeData[i].color}`)
                }
                this.barChartData_OpenCalls = [{
                    data: calls,
                    label: "",
                    backgroundColor: colors
                }]

                ////Calls By System Type
                this.pieChartLabels = [];
                this.pieChartData = [];
                this._pieChartDataArray = [];
                for (var i = 0; i < callsBySystemTypeData.length; i++) {
                    const newColor = RandomColor.generateColor();
                    this._pieChartDataArray.push(callsBySystemTypeData[i].count);
                    this.pieChartLabels.push(callsBySystemTypeData[i].callSystem);
                    this.pieChartColors[0].backgroundColor.push(newColor);
                }
                this.pieChartData = this._pieChartDataArray;

                ////Total Number of calls per month
                this.barChartDataSites_Monthly = [];
                this.barChartLabelsSites_Monthly = [];
                this.barChartDataSites_Monthly = [{ data: totalNumberOfCallsPerMonthData.counts, label: `` }]
                this.barChartLabelsSites_Monthly = totalNumberOfCallsPerMonthData.callMonthes

                //Calls By Site
                this.callsChartByYear = CallsBySiteData;
                this.barChartData_Annually = [];
                this.barChartLabels_Annually = [];
                for (var i = 0; i < CallsBySiteData.length; i++) {
                    this.barChartData_Annually.push({ data: CallsBySiteData[i].counts, label: `${CallsBySiteData[i].siteName}` })
                    this.barChartLabels_Annually = CallsBySiteData[i].years
                }

                ////draw Open Calls by Call Type
                this.barChartData_CallsByCallType = [];
                this.barChartLabels_CallsByCallType = [];
                var calls = [];
                var colors = [];
                for (var i = 0; i < CallsByCallTypeData.length; i++) {
                    this.barChartLabels_CallsByCallType.push(CallsByCallTypeData[i].callType)
                    calls.push(CallsByCallTypeData[i].calls)
                    colors.push(`#${CallsByCallTypeData[i].color}`)
                }
                this.barChartData_CallsByCallType = [{
                    data: calls,
                    label: "",
                    backgroundColor: colors
                }]

                //this.toastr.remove(toast.toastId);
                this.toastr.clear();
                this.toastr.success(loadedToastrText);
            },
            error => console.error(error));


    }
    //---start calendar---
    onDateSelection(date: NgbDate) {
        if (this.fromDate != null && this.toDate != null) {
            this.isPeriodFilterButton1Selected = false;
            this.isPeriodFilterButton2Selected = false;
            this.isPeriodFilterButton3Selected = false;
            this.isPeriodFilterButton4Selected = false;
            this._startYear = this.fromDate.year;
            this._endYear = this.toDate.year;
            this._startMonth = this.fromDate.month;
            this._endMonth = this.toDate.month;

            this.applyFilters(this._tokenservice.userEmail, null, this.slaID, "Filtering ...", "Filtered by Date");
        }
    }
    onClearDateFilter() {
        this._startYear = 2019;
        this._endYear = 2019;
        this._startMonth = 1;
        this._endMonth = 12;
        this.slaID = null;
        this.slaArray = ["within SLA", "Over SLA"];
        this.isPeriodFilterButton1Selected = false;
        this.isPeriodFilterButton2Selected = false;
        this.isPeriodFilterButton3Selected = true;
        this.isPeriodFilterButton4Selected = false;
        this.isSLAButton1Selected = false;
        this.isSLAButton2Selected = false;
        this.isSLAButton3Selected = true;
        
        this.fromDate = null;
        this.toDate = null;

        this.applyFilters(this._tokenservice.userEmail, Period.ThisYear, this.slaID, "Clearing ...", "Cleared!");


    }
    isHovered(date: NgbDate) {
        return this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    }

    isInside(date: NgbDate) {
        return date.after(this.fromDate) && date.before(this.toDate);
    }

    isRange(date: NgbDate) {
        return date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date);
    }
    //---end calendar---
    //----------------start chart annual by sites---------------->
    public barChartOptions_Annually: ChartOptions = {
        responsive: true,
        title: {
            text: 'Annual chart',
            fontColor: 'white',
            display: true,
        },
        legend: {
            display: false,
            position: "left",
            labels: {
            },
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Calls',
                },
                ticks: {
                }
            }],
            gridLines: {
                color: 'red',
            },
            yAxes: [{
                display: true,
                gridLines: {
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Sites',
                },
                ticks: {
                }
            }]
        }
    };
    public barChartLabels_Annually: Label[] = [' '];
    public barChartType_Annually: ChartType = 'horizontalBar';
    public barChartLegend_Annually = true;

    public barChartData_Annually: ChartDataSets[] = [
        { data: [0], label: 'Series B' }
    ];

    // events
    public chartClicked_Annually({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public chartHovered_Annually({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public randomize_Annually(): void {
        this.barChartType_Annually = this.barChartType_Annually === 'horizontalBar' ? 'line' : 'horizontalBar';
    }
    //----------------end chart annual by sites---------------->
    //----------------start chart Open Calls---------------->
    public barChartOptions_OpenCalls: ChartOptions = {
        responsive: true,

        title: {
            display: true,
        },
        legend: {
            display: false,
            position: "left",
            labels: {
            },
        },
        scales: {
            xAxes: [{
                display: true,
                gridLines: {
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Calls',
                },
                ticks: {
                }
            }],
            gridLines: {
                color: 'red',
            },
            yAxes: [{
                display: true,
                gridLines: {
                },
                scaleLabel: {
                    display: false,
                    labelString: 'Call Types',
                },
                ticks: {
                }
            }]
        }
    };
    public barChartLabels_OpenCalls: Label[] = [' '];
    public barChartType_OpenCalls: ChartType = 'horizontalBar';
    public barChartLegend_OpenCalls = true;

    public barChartData_OpenCalls: ChartDataSets[] = [
        {
            data: [0], label: '-'

        }
    ];

    // events
    public chartClicked_OpenCalls({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public chartHovered_OpenCalls({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public randomize_OpenCalls(): void {
        this.barChartType_OpenCalls = this.barChartType_OpenCalls === 'horizontalBar' ? 'line' : 'horizontalBar';
    }
    //----------------end chart Open Calls---------------->

    // ----------------- start Pie -----------------------
    public pieChartOptions: ChartOptions = {
        responsive: true,
        legend: {
            display: false
        },
        plugins: {
            labels: [{
                render: 'percentage',
                precision: 2,
                arc: false,
            },
            {
                render: 'label',
                position: 'outside',
            }
            ],
            datalabels: {
              display: false
            },
            tooltip: {
              enabled: false
            }
        },
    };
    public pieChartLabels: Label[] = [' '];
    public pieChartData: SingleDataSet = [0];
    public pieChartType: ChartType = 'pie';
    public pieChartLegend = true;
    public pieChartPlugins = [ChartDataLabels];
    public pieChartColors = [
        {
            backgroundColor: ['rgba(255,0,0,0.3)'],
        },
    ];
    changeLegendPosition() {
        this.pieChartOptions.legend.position = this.pieChartOptions.legend.position === 'left' ? 'top' : 'left';
    }
    //-------------------end Pie ------------------------

    //--------------start chart Monthly by year--------------
    //Total Number of calls per month
    public barChartOptionsSites_Monthly: ChartOptions = {
        responsive: true,
        legend: {
            display: false
        },
        plugins: {
            labels: false
        },
        title: {
            display: false,
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Month'
                },
            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Calls count'
                },
            }]
        }

    };
    public barChartLabelsSites_Monthly: Label[] = [' '];
    public barChartTypeSites_Monthly: ChartType = 'bar';
    public barChartLegendSites_Monthly = false;

    public barChartDataSites_Monthly: ChartDataSets[] = [
        { data: [0], label: '#1' },
        { data: [0], label: '#2' }
    ];

    // events
    public chartClickedSites_Monthly({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public chartHoveredSites_Monthly({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public randomizeSites_Monthly(): void {
        this.barChartTypeSites_Monthly = this.barChartTypeSites_Monthly === 'bar' ? 'line' : 'bar';
    }
    //--------------end chart Monthly by year--------------

    //--------------start chart for each site Monthly--------------
    public barChartOptionsSitesMonthly: ChartOptions = {
        responsive: true,
        legend: {
            display: false
        },

        title: {
            display: true,
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Month/Year'
                },


            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Calls count'
                }
            }]
        }
    };
    public barChartLabelsSitesMonthly: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    public barChartTypeSitesMonthly: ChartType = 'line';
    public barChartLegendSitesMonthly = true;

    public barChartDataSitesMonthly: ChartDataSets[] = [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Site #1' },
    ];

    // events
    public chartClickedSitesMonthly({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public chartHoveredSitesMonthly({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public randomizeSitesMonthly(): void {
        this.barChartTypeSitesMonthly = this.barChartTypeSitesMonthly === 'bar' ? 'line' : 'bar';
    }
    //--------------end chart for each site Monthly--------------
    //--------------start chart year by CallType--------------
    public barChartOptionsCallType: ChartOptions = {
        legend: {
            display: false
        },

        responsive: true,
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Month'
                },
            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Calls count'
                }
            }]
        }
    };
    public barChartLabelsCallType: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    public barChartTypeCallType: ChartType = 'line';
    public barChartLegendCallType = true;

    public barChartDataCallType: ChartDataSets[] = [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Site #1' },
    ];

    // events
    public chartClickedCallType({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public chartHoveredCallType({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public randomizeCallType(): void {
        this.barChartTypeCallType = this.barChartTypeCallType === 'bar' ? 'line' : 'bar';
    }
    //--------------end chart year by CallType --------------

    //--------------start chart year by CallType monthly--------------
    public barChartOptionsCallType_Monthly: ChartOptions = {
        legend: {
            display: false
        },
        title: {
            display: true,
        },
        scales: {
            xAxes: [{

                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Month'
                }
            }],
            yAxes: [{

                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Calls count'
                }
            }]
        }
    };
    public barChartLabelsCallType_Monthly: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    public barChartTypeCallType_Monthly: ChartType = 'line';
    public barChartLegendCallType_Monthly = true;

    public barChartDataCallType_Monthly: ChartDataSets[] = [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Site #1' },
    ];

    // events
    public chartClickedCallType_Monthly({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public chartHoveredCallType_Monthly({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public randomizeCallType_Monthly(): void {
        this.barChartTypeCallType_Monthly = this.barChartTypeCallType_Monthly === 'bar' ? 'line' : 'bar';
    }
    //--------------end chart year by CallType monthly--------------

    //--------------start chart year by SLA monthly--------------
    public barChartOptions_SLA: ChartOptions = {
        legend: {
            display: true
        },
        responsive: true,
        title: {
            display: true,
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Month'
                }
            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Calls count*'
                }
            }]
        }
    };

    public barChartLabels_SLA: Label[] = ['2006', '2007', '2008', '2009', '2010', '2011', '2012'];
    public barChartType_SLA: ChartType = 'bar';
    public barChartLegend_SLA = true;

    public barChartData_SLA: ChartDataSets[] = [
        { data: [65, 59, 80, 81, 56, 55, 40], label: 'Site #1' },
    ];

    // events
    public chartClicked_SLA({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public chartHovered_SLA({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public randomize_SLA(): void {
        this.barChartType_SLA = this.barChartType_SLA === 'bar' ? 'line' : 'bar';
    }
    //--------------end chart year by SLA monthly--------------

    //--------------start chart year by CallsByCallType--------------
    public barChartOptions_CallsByCallType: ChartOptions = {
        legend: {
            display: false
        },

        responsive: true,
        title: {
            display: true,
        },
        scales: {
            xAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Calls count'
                }
            }],
            yAxes: [{
                display: true,
                scaleLabel: {
                    display: true,
                    labelString: 'Call Type'
                }
            }]
        }
    };
    public barChartLabels_CallsByCallType: Label[] = [' '];
    public barChartType_CallsByCallType: ChartType = 'horizontalBar';
    public barChartLegend_CallsByCallType = true;

    public barChartData_CallsByCallType: ChartDataSets[] = [
        { data: [0], label: 'Site #1' },
    ];

    // events
    public chartClicked_CallsByCallType({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public chartHovered_CallsByCallType({ event, active }: { event: MouseEvent, active: {}[] }): void {
    }

    public randomize_CallsByCallType(): void {
        this.barChartType_CallsByCallType = this.barChartType_SLA === 'bar' ? 'line' : 'bar';
    }
    //--------------end chart year by CallsByCallType--------------
}

interface ChartDataTypes {
    chartData: any;
    callTypes: any;
}

interface ChartPieData {
    count: number;
    siteName: string;
}

interface ChartMonthlyData {
    counts: number[];
    siteName: string;
    callMonthes: string[];
    years: string[];

}

interface MonthlyData {
    chartData: ChartMonthlyData[];
    years: number;
}

interface UserSites {
    siteName: any;
    isChecked: boolean;
}

interface CallsByCallType {
    callsCount: any;
    callType: any;
    color: any;
}
