import { Component } from '@angular/core';

@Component({
  selector: 'app-page-unavailable',
  templateUrl: './page-unavailable.component.html'
})

export class PageUnavailableComponent  {

}
