import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard, MsalRedirectComponent } from '@azure/msal-angular';
import { BrowserUtils } from '@azure/msal-browser';
import { AccessDeniedComponent } from './access-denied';
import { AdminComponent } from './admin';
import { CallDetailsComponent } from './call-details/call-details.component';
import { CallHistoryComponent } from './call-history/call-history.component';
import { LogCallComponent } from './log-call/log-call.component';
import { HomeComponent } from './home/home.component';
import { PageUnavailableComponent } from './page-unavailable/page-unavailable.component';
import { ReportingComponent } from './reporting/reporting.component';
import { TranslateComponent } from './translate/translate.component';
import { AuthGuard } from './_guards';
import { Role } from './_models/role';

/**
 * MSAL Angular can protect routes in your application using MsalGuard. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#secure-the-routes-in-your-application
 */
const routes: Routes = [
    { path: '', component: HomeComponent },
    { path: 'denied', component: AccessDeniedComponent },
    { path: 'page-unavailable', component: PageUnavailableComponent },
    { path: 'reporting', component: ReportingComponent, canActivate: [MsalGuard, AuthGuard], data: { roles: [Role.InspiredAllAccess, Role.SiteManager] } },
    { path: 'call-history', component: CallHistoryComponent, canActivate: [MsalGuard, AuthGuard] },
    { path: 'translate', component: TranslateComponent },
    { path: 'call-details/:id', component: CallDetailsComponent, canActivate: [MsalGuard, AuthGuard] },
    { path: 'admin', component: AdminComponent, canActivate: [MsalGuard, AuthGuard], data: { roles: [Role.InspiredAllAccess] } },
    { path: 'log-a-call', component: LogCallComponent, canActivate: [MsalGuard, AuthGuard] },
    { path: 'home', redirectTo: '' },
    { path: 'auth', component: MsalRedirectComponent },        // Needed for handling redirect after logging in    
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        // Don't perform initial navigation in iframes or popups
        initialNavigation: !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup() ? 'enabledNonBlocking' : 'disabled' // Set to enabledBlocking to use Angular Universal
    })],
    exports: [RouterModule]
})

export class AppRoutingModule { }
