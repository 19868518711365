import { Component, OnInit } from '@angular/core';
import { first } from 'rxjs/operators';
import { UserService } from '../_services';
import { User } from '../_models';
import { RolesService } from '../_services/roles.service';

@Component({
    templateUrl: 'admin.component.html', 
    styles: ['#admin-content { background-color: white; padding: 9px;}'] 
})

export class AdminComponent implements OnInit {
    users: User[] = [];
    message: string;

    constructor(
        private roleService: RolesService,
        private userService: UserService
    ) { }

    ngOnInit() {
        if (this.roleService.isInspiredAllAccess)
        {
            this.userService.getAll().pipe(first()).subscribe(
                (users => {
                    this.users = users;
                }),
                (err => {
                    this.message = 'Sorry - there is a problem with the data';
                })
            );
        }
    }
}
