<div class="content">
  <div class="container">
    <br />
    <div class="row">
      <div class="col-sm-12">
        <br />
        <h3 class="text-center">CALL INFORMATION</h3>
        <hr />
      </div>

    </div>
    <form [formGroup]="callForm" (ngSubmit)="onSubmit()">
      <div class="row">

        <div class="col-sm-6">
          <div class="form-group">
            <label>Customer </label>
            <input type="text" formControlName="customer" class="form-control" [attr.disabled]="true" value="{{userInfo?.clients.title1}}" />
          </div>
          <div class="form-group">
            <label>Site </label>
            <input type="text" class="form-control" [(ngModel)]="selectedSiteName" [attr.disabled]="true" value={{sitesNames[0]?.siteName}} *ngIf="sitesNames?.length == 1 else multiSites"/>
            <ng-template #multiSites>
            <select class='form-control' [(ngModel)]="selectedSite" [ngModelOptions]="{standalone: true}" >
              <option [ngValue]="site.siteID" *ngFor="let site of sitesNames">
                {{ site.siteName }}
              </option>
            </select>
            </ng-template>
            
          </div>
          <div class="form-group">
            <label>Caller  </label>
            <input type="text" formControlName="caller" class="form-control" [attr.disabled]="true" />
          </div>
          <div class="form-group">
            <label for="description">Fault Description </label>
            <textarea class="form-control" rows="8" type="text" formControlName="description" [ngClass]="{ 'is-invalid': submitted && f.description.errors }"></textarea>
            <div *ngIf="submitted && f.description.errors" class="invalid-feedback">
              <div *ngIf="f.description.errors.required">Description is required</div>
            </div>
          </div>

          <div class="form-group">
            <input type="file" accept="*" #file placeholder="Choose file for uploading to Azure Blob" (change)="azureUploadFile(file.files)" style="display:none;" multiple>
            <button type="button" class="btn btn-secondary" (click)="file.click()">Add Files</button>
          </div>

        </div>
        <div class="col-sm-6">
          <div class="form-group">
            <label>Caller Email</label>
            <input type="text" formControlName="callerEmail" class="form-control" [attr.disabled]="true" />
          </div>
          <div class="form-group">
            <label>Caller Job Title  </label>
            <input type="text" formControlName="callerJobTitle" class="form-control" [attr.disabled]="true" />
          </div>

          <div class="form-group">
            <label>Caller Telephone</label>
            <input type="text" formControlName="callerTelephone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.callerTelephone.errors }" />
            <div *ngIf="submitted && f.callerTelephone.errors" class="invalid-feedback">
              <div *ngIf="f.callerTelephone.errors.required">Caller Telephone is required</div>
              <div *ngIf="f.callerTelephone.errors.pattern">Caller Telephone isn't valid number</div>
            </div>
          </div>
          <div class="form-group">
            <label>Caller Mobile</label>
            <input type="text" formControlName="callerMobile" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.callerMobile.errors }" />
            <div *ngIf="submitted && f.callerMobile.errors" class="invalid-feedback">
              <div *ngIf="f.callerMobile.errors.required">Caller Mobile is required</div>
              <div *ngIf="f.callerMobile.errors.pattern">Caller Mobile isn't valid number</div>

            </div>
          </div>

          <div class="form-group">
            <label for="callType">Call Type </label>
            <select class='form-control' [(ngModel)]="selectedCallType" [ngModelOptions]="{standalone: true}">
              <option [ngValue]="callType.idCallTypes" *ngFor="let callType of callTypes">
                {{ callType.ctDesc }}
              </option>
            </select>
            <div *ngIf="submitted && f.callType.errors" class="invalid-feedback">
              <div *ngIf="f.callType.errors.required">Call Type is required</div>
            </div>
          </div>
          <div class="form-group">
            <label for="system">System </label>
            <select class='form-control' [(ngModel)]="selectedCallSystem" [ngModelOptions]="{standalone: true}" id="system">
              <option [ngValue]="callSystem.iD_CallSys" *ngFor="let callSystem of callSystems">
                {{ callSystem.cSys_DESC }}
              </option>
            </select>
            <div *ngIf="submitted && f.system.errors" class="invalid-feedback">
              <div *ngIf="f.system.errors.required">System is required</div>
            </div>
          </div>
          <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">Submit the Call</button>
            <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
          </div>

        </div>
      </div>

    </form>
    <hr />
    <div>
      <img src="IISYSTEMSlogoWHT150px.png" />&nbsp;&nbsp;&nbsp;&nbsp; Copyright © 2016 Inspired Integrated Systems. All rights reserved.
    </div>
  </div>
</div>
