import { Injectable, Inject } from '@angular/core';
import { Role } from '../_models/role';
import { TokenService } from '.';

@Injectable()
export class RolesService {

    constructor(
        private tokenservice: TokenService
    ) { }

    get isInspiredAllAccess() {
        return this.tokenservice.userRole === Role.InspiredAllAccess;
    }

    get isSiteManager() {
        return this.tokenservice.userRole === Role.SiteManager;
    }

    get isSecurityGuard() {
        return this.tokenservice.userRole === Role.SecurityGuard;
    }

}
