import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AccountInfo, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { User } from '../_models';
import { filter, first, takeUntil } from 'rxjs/operators';

@Injectable()
export class AuthenticationService {
    private baseUrl: any;
    private currentUserSubject: BehaviorSubject<User>;
    private msalAccount: AccountInfo;
    private readonly _destroying$ = new Subject<void>();

    public currentUser$: Observable<User>;
    public loggedInUser$: Subject<boolean> = new Subject();
    
    constructor(
        private http: HttpClient,
        private msalSvc: MsalService,
        private router: Router,
        private msalAuthSvc: MsalService,
        private msalBroadcastService: MsalBroadcastService,   
        @Inject('BASE_URL') baseUrl: string
    ) {
        this.baseUrl = baseUrl;
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('adalcurrent')));
        this.currentUser$ = this.currentUserSubject.asObservable();
    }
   
    public manageUserLoginStatus() {
        this.msalBroadcastService.inProgress$
            .pipe(
                filter(
                (status: InteractionStatus) => status === InteractionStatus.None
                ),
                takeUntil(this._destroying$)
            )
            .subscribe(() => {
                const userAccount = this.msalAuthSvc.instance.getAllAccounts().length > 0;
                if (userAccount) {
                    console.log('MSAL - inProgress: User Is logged in');
                    this.msalAccount = this.msalAuthSvc.instance.getAllAccounts()[0];
                    this.loggedInUser$.next(true);
                    this.loadUserRole();
                } else {
                    console.log('MSAL - inProgress: User NOT logged in');
                    //console.log('MSAL - inProgress: NOT logged in - send to loginRedirect');
                    //  UNCOMMENT TO SEND STRAIGHT TO MS Login --->   this.msalAuthSvc.loginRedirect();
                }
            });
    }

    public get currentUserValue(): User {
        const user = JSON.parse(localStorage.getItem('adalcurrent'));
        return user;
    }

    public get loggedInAccount() {
        return this.msalAccount;
    }

    //confirm user exists and get their role (by email address), after azure active directory logging in
    loadUserRole() {
        if (this.msalAccount && this.msalAccount.username)
        {
          this.http.get<any>(`${this.baseUrl}users/getUsersRole/${this.msalAccount.username}`)
            .subscribe(
                (user) => {
                    if (user) {
                        const adalUser: User = new User();
                        adalUser.displayName = user.displayName;
                        adalUser.role = user.role.name;
                        adalUser.email = user.username;
                        adalUser.id = user.id;

                        // store user details in local storage to keep user logged in between page refreshes
                        localStorage.setItem('adalcurrent', JSON.stringify(adalUser));
                        this.currentUserSubject.next(adalUser);
                        console.log('loadUserRole', adalUser);
                    } else {
                        localStorage.removeItem('adalcurrent');
                        this.currentUserSubject.next(null);
                        console.log('loadUserRole - no User found');
                    }
                },
                (error) => {
                    localStorage.removeItem('adalcurrent');
                    this.currentUserSubject.next(null);
                    console.log('loadUserRole - no User in table');
                    this.router.navigate(['/page-unavailable']);
                });
        }
    }

    doLogout() {
        this.http.post<any>(`${this.baseUrl}api/calls/ClearSession`, null).subscribe();
        //remove user from local storage to log user out
        localStorage.removeItem('adalcurrent');
        this.currentUserSubject.next(null);
        this.msalSvc.logout();
        this.router.navigate(['/']);
    }

}
