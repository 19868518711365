<h1>Admin</h1>
<p>This page can only be accessed by administrators.</p>

<div class="container" id="admin-content">
  <h3>All users from secure (admin only) api end point</h3>
  <ul>
    <li *ngFor="let user of users">
      {{user.username}} ({{ user.displayName }})
      <!-- - <a (click)="deleteUser(user.id)" class="text-danger">Delete</a> -->
    </li>
  </ul>
  <h4>{{message}}</h4>
</div>
