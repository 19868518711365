<div class="call-history-content">
  <div *ngIf="isReady">
    <br />
    <h3>Calls History </h3>
    <!-----start calendar ----->
    <div class="row">
      <div class="col-sm-6 row">
        <form class="input-holder">
          <div class="">
            <div class="input-group">
              <input class="form-control" placeholder="FROM DATE"
                     name="dp" [(ngModel)]="fromDate" ngbDatepicker #_d="ngbDatepicker" (ngModelChange)="onDateSelection($event)">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="_d.toggle()" type="button" style="background-color:wheat"><i class="fa fa-calendar"></i></button>
              </div>
            </div>
          </div>
        </form>
        <form class="input-holder">
          <div class="">
            <div class="input-group">
              <input class="form-control" placeholder="TO DATE"
                     name="dp" [(ngModel)]="toDate" ngbDatepicker #d="ngbDatepicker" (ngModelChange)="onDateSelection($event)">
              <div class="input-group-append">
                <button class="btn btn-outline-secondary calendar" (click)="d.toggle()" type="button" style="background-color:wheat"><i class="fa fa-calendar"></i></button>
              </div>
            </div>
          </div>
        </form>
        <button class="btn clear-btn" (click)="onClearDateFilter()">Clear Date Filter</button>

      </div>
      <div class="col-sm-6">
        <div id="dateFilter">
          <button type="button" class="btn btn-secondary btn-sm" [style.background-color]="isPeriodFilterButton1Selected ? '#3fae29' : '#6c757d'" (click)="filterByThisMonth();changePeriodFilterButton1()">This month</button>
          <button type="button" class="btn btn-secondary btn-sm" [style.background-color]="isPeriodFilterButton2Selected ? '#3fae29' : '#6c757d'" (click)="filterByLastMonth();changePeriodFilterButton2()">Last month</button>
          <button type="button" class="btn btn-secondary btn-sm" [style.background-color]="isPeriodFilterButton3Selected ? '#3fae29' : '#6c757d'" (click)="filterByThisYear();changePeriodFilterButton3()">This year</button>
          <button type="button" class="btn btn-secondary btn-sm" [style.background-color]="isPeriodFilterButton4Selected ? '#3fae29' : '#6c757d'" (click)="filterByLastYear();changePeriodFilterButton4()">Last year</button>

        </div>
      </div>
    </div>
    <!--*******************************-->
    <div>
      <br />
      <ng-multiselect-dropdown [placeholder]="'FILTER STATUS'"
                               [data]="statuses"
                               [(ngModel)]="selectedStatuses"
                               [settings]="statusDropdownSettings"
                               (onSelect)="onItemSelectStatus($event)"
                               (onSelectAll)="onSelectAllStatus($event)"
                               (onDeSelect)="onItemDeSelectStatus($event)">
      </ng-multiselect-dropdown>

    </div>
    <!--*******************************-->
    <!--*******************************-->
    <div>
      <br />
      <ng-multiselect-dropdown [placeholder]="'FILTER CALL TYPE'"
                               [data]="calltypes"
                               [(ngModel)]="selectedCallTypes"
                               [settings]="callTypeDropdownSettings"
                               (onSelect)="onItemSelectCalltype($event)"
                               (onSelectAll)="onSelectAllCalltype($event)"
                               (onDeSelect)="onItemDeSelectCalltype($event)">
      </ng-multiselect-dropdown>

    </div>
    <!--*******************************-->
    <div>
      <br />
      <ng-multiselect-dropdown [placeholder]="'FILTER SITE'"
                               [data]="_sites"
                               [(ngModel)]="selectedSites"
                               [settings]="sitesDropdownSettings"
                               (onSelect)="onItemSelectSites($event)"
                               (onSelectAll)="onSelectAllSites($event)"
                               (onDeSelect)="onItemDeSelectSites($event)">
      </ng-multiselect-dropdown>

    </div>
    <!--*******************************-->
    <!--*******************************-->
    <div *ngIf="_roleService.isInspiredAllAccess">
      <br />
      <ng-multiselect-dropdown [placeholder]="'FILTER CLIENT'"
                               [data]="_customers"
                               [(ngModel)]="selectedCustomers"
                               [settings]="customersDropdownSettings"
                               (onSelect)="onItemSelectCustomers($event)"
                               (onSelectAll)="onSelectAllCustomers($event)"
                               (onDeSelect)="onItemDeSelectCustomers($event)">
      </ng-multiselect-dropdown>

    </div>
    <!--*******************************-->
    <!-----end calendar ----->
    <hr />

  </div>
  <p *ngIf="!isReady"><em>Loading...</em></p>



  <div>
    <table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover" *ngIf="calls" width="100%">

      <thead style="background-color: #6c757d">
        <tr>
          <th>Call Number</th>
          <th>Caller</th>
          <th>Status</th>
          <th>Site</th>
          <th>Call Type</th>
          <th>Description</th>
          <th>Reported</th>
          <th></th>
        </tr>

      </thead>
      <tbody>
        <tr *ngFor="let call of calls" [ngStyle]="{'background-color': '#' + call.cT_COLOUR}">
          <td>{{ call.callNumber}}</td>
          <td>{{ call.caller }}</td>
          <td>{{ call.status }}</td>
          <td>{{ call.site }}</td>
          <td>{{ call.callType }}</td>
          <td>{{ call.description }}</td>
          <td>{{ call.reported | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
          <td><button class="btn btn-info" [routerLink]="[ '/call-details',call.callNumber]">Details</button></td>
        </tr>
      </tbody>
    </table>
  </div>

  <div *ngIf="isReady" style="margin: 5px;">
    <div class="row">
        <p style="color: white; margin-left: 10px;">
          Data is cached, please, click <a  [routerLink]="[]" (click)="onClearSession()" style="color: #3fae29;">here</a>
          to get the latest data
        </p>
      </div>
  </div>
</div>
