<header>
  <div class="contactPhone">
    <div class="container text-right"><i class="fas fa-phone-alt"></i> 01707 397 000</div>
    <div class="container text-right"> <a href="mailto: thehub@inspiredsystems.co.uk">thehub@inspiredsystems.co.uk</a> </div>
  </div>
  <nav class='navbar navbar-expand-md navbar-toggleable-md navbar-light bg-white border-bottom box-shadow mb-3'>
    <div class="container">
      <a class="navbar-brand" [routerLink]='["/"]'> <img src="IISlogo230px.png" /> </a>

      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target=".navbar-collapse" aria-label="Toggle navigation"
              [attr.aria-expanded]="isExpanded" (click)="toggle()">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="navbar-collapse collapse d-md-inline-flex flex-md-row-reverse" [ngClass]='{"show": isExpanded}'>
        <ul class="navbar-nav flex-grow">
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="userAccount">
            <a class="nav-link text-dark" [routerLink]='["/log-a-call"]'>LOG A CALL</a>
          </li>
          <li class="nav-item" [routerLinkActive]='["link-active"]' *ngIf="userAccount">
            <a class="nav-link text-dark" [routerLink]='["/call-history"]'>CALL HISTORY</a>
          </li>
          <li *ngIf="userAccount && !roleService.isSecurityGuard" class="nav-item" [routerLinkActive]='["link-active"]'>
            <a class="nav-link text-dark" [routerLink]='["/reporting"]'>REPORTING DASHBOARD</a>
          </li>
          <li class="nav-item" *ngIf="isLoggedIn">
            <button type="button" class="btn btn-link" (click)="logOut()">Log Out</button>
          </li>
          <li class="nav-item" *ngIf="!isLoggedIn">
            <button type="button" class="btn btn-link" (click)="logIn()">Log In</button>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</header>
