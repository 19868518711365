import { Injectable } from '@angular/core';
import { AuthenticationService } from '../_services';

@Injectable()
export class TokenService {

    constructor(
        private authenticationService: AuthenticationService
    ) { }

    public get userEmail(): string {
        const user = this.authenticationService.currentUserValue;
        if (user && user.email) return user.email;
        else return null;
    }

    public get userRole(): string {
        const user = this.authenticationService.currentUserValue;
        if (user && user.role) return user.role;
        else return null;
    }

}
