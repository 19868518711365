import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthenticationService } from '../_services';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})

export class HomeComponent implements OnInit {
    
    isLoggedIn: boolean;

    constructor(
        private _DomSanitizationService: DomSanitizer,
        private authenticationService: AuthenticationService,
    ) { 
        this.isLoggedIn = (this.authenticationService.currentUserValue != null);
    
        this.authenticationService.loggedInUser$.subscribe(
          (result) => {
            this.isLoggedIn = result;
            console.log('HOME - LOGGED IN', result);
          }
        )
    }

    public ngOnInit():void {
        const currentUser = this.authenticationService.currentUserValue;
        console.info("Home: USER ACCOUNT", currentUser);
        this.isLoggedIn = (currentUser != null);
    }
}