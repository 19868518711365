import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Responsive from 'datatables.net-responsive';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { TokenService } from '../_services';
import { RolesService } from '../_services/roles.service';

@Component({
    selector: 'app-call-details-component',
    templateUrl: './call-details.component.html',
    styleUrls: ['./call-details.component.css']
})

export class CallDetailsComponent implements OnInit {
    dtOptions: any = {};
    dtOptions1: any = {};
    dtOptions2: any = {};
    dtOptions3: any = {};
    dtOptions4: any = {};
    call: any;
    sub: any;
    id: number;
    statuses: number[] = [];
    selectedStatus: any;
    baseUrl: any;
    uploadedFiles: any[] = [];
    _tokenservice: TokenService;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private _location: Location,
        private http: HttpClient,
        private roleService: RolesService,
        @Inject('BASE_URL') baseUrl: string,
        tokenservice: TokenService,
        private toastr: ToastrService
    ) {
        this._tokenservice = tokenservice;
        this.baseUrl = baseUrl;

        this.sub = this.route.params.subscribe(params => {
            this.id = params['id'];
        });

        if (this.isInspiredAllAccess || this.isSiteManager) {
            this.getStatuses();
        }
        this.getCall();
    }

    ngOnInit(): void {
        this.dtOptions = {
            "bPaginate": false,
            "bFilter": false,
            "ordering": false,
            responsive: {// Use this attribute to enable the responsive extension with click on buttons in mobile view
                details: {
                    renderer: Responsive.renderer.listHiddenNodes()
                }
            },
        };
        this.dtOptions1 = {
            "bPaginate": false,
            "bFilter": false,
            "ordering": false,
            responsive: {// Use this attribute to enable the responsive extension with click on buttons in mobile view
                details: {
                    renderer: Responsive.renderer.listHiddenNodes()
                }
            },
        };
        this.dtOptions2 = {
            "bPaginate": false,
            "bFilter": false,
            "ordering": false,
            language: {
                "zeroRecords": " "
            },
            responsive: {// Use this attribute to enable the responsive extension with click on buttons in mobile view
                details: {
                    renderer: Responsive.renderer.listHiddenNodes()
                }
            },
        };
        this.dtOptions3 = {
            "bPaginate": false,
            "bFilter": false,
            "ordering": false,
            responsive: {// Use this attribute to enable the responsive extension with click on buttons in mobile view
                details: {
                    renderer: Responsive.renderer.listHiddenNodes()
                }
            },
        };
        this.dtOptions4 = {
            "bPaginate": false,
            "bFilter": false,
            "ordering": false,
            responsive: {// Use this attribute to enable the responsive extension with click on buttons in mobile view
                details: {
                    renderer: Responsive.renderer.listHiddenNodes()
                }
            },
        };
    }

    get userEmail() {
        return this._tokenservice.userEmail;
    }

    get isInspiredAllAccess() {
        return this.roleService.isInspiredAllAccess;
    }

    get isSecurityGuard() {
        return this.roleService.isSecurityGuard;
    }

    get isSiteManager() {
        return this.roleService.isSiteManager;
    }

    getCall() {
        this.http.get<any>(`${this.baseUrl}api/calls/getcall/${this.id}`).subscribe(result => {
            if (result == null) {
                this.router.navigate(['/page-unavailable']);
            }
            this.call = result;
            this.selectedStatus = this.call.status;
            this.getFiles(this.call.callNumber);
        }, error => {
                if (error == 'One or more validation errors occurred.') {
                    this.router.navigate(['/page-unavailable']);
                }
        }
    )}

    backClicked() {
        this._location.back();
    }

    save() {
        this.call.status = this.selectedStatus;
        this.call.newCallUrl = this.baseUrl
        this.http.post(`${this.baseUrl}api/calls/UpdateCall`, this.call)
            .subscribe(result => {
                this.toastr.success("Successfully updated!");
                this.getCall();
            })
        const Toast = Swal.mixin({
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 2000
        });

        //Toast.fire({
        //  type: 'success',
        //  title: 'Successfully updated!'
        //})
    }

    cancel() {

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this! Please add a reason of canceling",
            type: 'warning',
            input: 'text',
            showCancelButton: true,
            confirmButtonColor: '#3fae29',
            cancelButtonColor: '#d33',
            cancelButtonText: 'No',
            confirmButtonText: 'Yes, cancel the Call!',
            preConfirm: (data) => {
                this.call.solution = data;
            },
            inputValidator: (value) => {
                if (!value) {
                    return 'Please provide the cancellation reason'
                }
            },
        }).then((result) => {
            if (result.value) {
                this.call.newCallUrl = this.baseUrl;
                this.http.post(`${this.baseUrl}api/calls/CancelCall`, this.call)
                    .subscribe(result => {
                        this.getCall();
                        Swal.fire(
                            'Canceled!',
                            'Call is canceled',
                            'success'
                        )
                    })
            }
        })
        //this.toastr.info("Call is canceled");
    }

    getStatuses() {
        this.http.get<number[]>(`${this.baseUrl}api/calls/getstatuses`)
            .subscribe(
                data => {
                    this.statuses = data;
                },
                error => console.error(error));
    }

    getFiles(callNumber: number) {
        this.http.get(`${this.baseUrl}api/calls/GetFiles/${callNumber}`)
            .subscribe(
                data => {
                    this.call.uploadedFiles = data;
                },
                error => console.error(error));
    }

    //azure file upload
    public azureUploadFile = (files) => {
        let filesToUpload: File[] = this.fileExtensionCheck(files).slice();
        if(filesToUpload.length === 0){
            return;
        }
        const formData = new FormData();

        Array.from(filesToUpload).map((file, index) => {
            return formData.append('file' + index, file, file.name);
        });
        formData.append('CallNumber', this.call.callNumber);
        this.http.post<any>(`${this.baseUrl}api/calls/UploadFilesByCallNumber`, formData)
            .subscribe(result => {

                if (result.length == 1) {
                    this.toastr.success("File is added");
                }
                else {
                    this.toastr.success("Files are added");
                }
                this.uploadedFiles = result;
                this.getFiles(this.call.callNumber);
            },
                error => {
                    this.toastr.error("Error uploading the file(s), please try again later")
                    console.error(error)
                });
    }

    // This check is done backend too
    public fileExtensionCheck(filesToUpload: File[]): File[] {
        var filesToReturn: File[] = [];
        const extReject: string[] = ['.acc', '.ade', '.adp ', '.asp', '.bas', '.bat', '.ccs', '.chm', '.class', '.cmd', '.com', '.cpl', '.crt', '.dll', '.dmg', '.docm', '.dotm', '.drv', '.exe', '.grp', '.hlp', '.hta', '.htx', '.ink', '.ins', '.isp', '.jar', '.je', '.js', ' .js', '.jse', '.ldb', '.lnk', '.mad', '.mda', '.mdb', '.mde', '.mdz', '.msc', '.msh', '.msh1', '.msh1xml', '.msh2', '.msh2xml ', '.mshxml', '.msi', '.msp', '.mst', '.ocx', '.ovl', '.pcd', '.php', '.php3', '.pif', '.potm', '.ppam', '.ppsm', '.pptm', '.ps1', '.ps1xml', '.ps2', '.ps2xml', '.psc1', ' .psc2', '.reg', '.sbs', '.scf', '.scr', '.sct', '.shb', '.shd', '.shs', '.sldm', '.snp', '.sys', '.vb', '.vba', '.vbe', '.vbs', '.vdl', '.vxd', '.ws', '.wsc', '.wsf', '.wsh', '.wst', '.xlam', '.xlsm', '.xltm'];

        for (var file of filesToUpload) {
            var fileName = file.name

            var extension = fileName.substring(fileName.lastIndexOf('.'));
            if (extReject.includes(extension))
            {
                this.toastr.error(`Could not allow the upload of ${fileName}`, `Content Upload Policy`);
            }
            else 
            {
                filesToReturn.push(file)
            }
        }
        return filesToReturn
    }
}
