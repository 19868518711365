import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing-module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { TranslateComponent } from './translate/translate.component';
import { HttpClient } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { DataTablesModule } from 'angular-datatables';
import { RandomcolorModule } from 'angular-randomcolor';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { ToastrModule } from 'ngx-toastr';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { AdminComponent } from './admin';
import { CallDetailsComponent } from './call-details/call-details.component';
import { CallHistoryComponent } from './call-history/call-history.component';
import { LogCallComponent } from './log-call/log-call.component';
import { PageUnavailableComponent } from './page-unavailable/page-unavailable.component';
import { ReportingComponent } from './reporting/reporting.component';
import { AuthGuard } from './_guards';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { AlertService, AuthenticationService, UserService } from './_services';
import { RolesService } from './_services/roles.service';
import { TokenService } from './_services/token.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService,
         MsalGuardConfiguration, MsalRedirectComponent, ProtectedResourceScopes,
         MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

@NgModule({
    declarations: [
        AppComponent,
        NavMenuComponent,
        HomeComponent,
        LogCallComponent,
        ReportingComponent,
        CallHistoryComponent,
        TranslateComponent,
        AdminComponent,
        CallDetailsComponent,
        AccessDeniedComponent,
        PageUnavailableComponent,
    ],

    imports: [
        NgbModule,
        MsalModule,
        AppRoutingModule,
        NgMultiSelectDropDownModule.forRoot(),
        BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
        BrowserAnimationsModule, // required animations module
        HttpClientModule,
        FormsModule,
        ChartsModule,
        DataTablesModule,
        ReactiveFormsModule,
        RandomcolorModule,
        ToastrModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        },
        { 
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true 
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        AuthGuard,
        AlertService,
        AuthenticationService,
        TokenService,
        UserService,
        RolesService,
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})

export class AppModule { }


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */
export function MSALInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources
 * added to protectedResourceMap. For more info, visit:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();

    protectedResourceMap.set(protectedResources.callManagerAPI.endpoint, [
        {
            httpMethod: 'GET',
            scopes: [...protectedResources.callManagerAPI.scope]
        },
        {
            httpMethod: 'POST',
            scopes: [...protectedResources.callManagerAPI.scope]
        },
        {
            httpMethod: 'PUT',
            scopes: [...protectedResources.callManagerAPI.scope]
        },
        {
            httpMethod: 'DELETE',
            scopes: [...protectedResources.callManagerAPI.scope]
        }
    ]);

    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap,
    };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}


// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
