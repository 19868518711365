<div class="content">
  <div class="container">
    <br />
    <div class="vc-row-translate-wrapper" style="padding-top: 0px; padding-bottom: 0px;">
      <div class="vc_col-sm-12 wpb_column column_container " style="transform: translateY(0px);">
        <div class="wpb_wrapper">
          <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner"></span></div>

          <div class="wpb_text_column wpb_content_element  vc_custom_1456230639612 fadeInDown animate-element animation-triggered start-animation">
            <div class="wpb_wrapper">
              <h6 style="text-align: justify; color:#333333!important">
                Thank you for using our Web-Portal. We have designed this portal to suit the requirements of our clients. Our objective is to give you a dynamic way to log faults as well as a transparent view of our service levels and overall performance. <br /> <br />

                Continual improvement is at the heart of our business and your feedback on how we can improve our overall support is important to us. If you have any suggestions to enhance this facility or any other aspect of our service then please let our office team know on thehub@inspiredsystems.co.uk <br /> <br />

                Thank you for your continued business.
              </h6>

            </div>
          </div> <div class="vc_empty_space" style="height: 32px"><span class="vc_empty_space_inner"></span></div>

        </div>
      </div>
    </div>
    <div class="row">
      <!--  -->
      <div class="col-sm-12">
        <br />
        <h3 *ngIf="!isLoggedIn" class="text-center">PLEASE LOG IN</h3>
        <hr />
      </div>
    <hr />
    <div>
      <img src="IISYSTEMSlogoWHT150px.png" />&nbsp;&nbsp;&nbsp;&nbsp; Copyright © 2016 Inspired Integrated Systems. All rights reserved.
    </div>
  </div>
</div>
