import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbCalendar, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import Responsive from 'datatables.net-responsive';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FilterData } from '../_models/filterData';
import { TokenService } from '../_services';
import { RolesService } from '../_services/roles.service';

@Component({
  selector: 'app-call-history',
  templateUrl: './call-history.component.html',
  styleUrls: ['./call-history.component.css']
})

export class CallHistoryComponent implements OnDestroy, OnInit, AfterViewInit {
  public calls: any[] = [];
  http: HttpClient;
  baseUrl: any;
  isCallsTableShowed: boolean = false;
  isReady: boolean = false;
  currentUser: any;
  dtOptions: any = {};
  values = '';
  callType: any;
  selectedStatuses: string[] = [];
  selectedCustomers: string[] = [];
  selectedSites: string[] = [];
  selectedCallTypes: string[] = [];
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;
  dtInstance: DataTables.Api;
  statuses: string[];
  calltypes: string[];
  _sites: string[];
  _customers: string[];
  dtTrigger: any = new Subject();
  hoveredDate: NgbDate;
  fromDate: NgbDate;
  toDate: NgbDate;
  statusDropdownSettings = {};
  callTypeDropdownSettings = {};
  sitesDropdownSettings = {};
  customersDropdownSettings = {};
  dateYearMonth: any;
  month: any;
  year: any;
  isRangeSelected: boolean = false;
  filterData: FilterData;
  isPeriodFilterButton1Selected: boolean;
  isPeriodFilterButton2Selected: boolean;
  isPeriodFilterButton3Selected: boolean;
  isPeriodFilterButton4Selected: boolean;
  isAccessToAllSites: boolean;
  _tokenservice: TokenService;
  _roleService: RolesService;

  constructor(
    http: HttpClient,
    @Inject('BASE_URL') baseUrl: string,
    private toastr: ToastrService,
    public calendar: NgbCalendar,
    tokenService: TokenService,
    roleService: RolesService
  ) {
    this.http = http;
    this.baseUrl = baseUrl;
    //this.isPeriodFilterButton3Selected = true;
    this._tokenservice = tokenService;
    this._roleService = roleService;
    this.toastr.info("Loading ...");
  }

  ngOnInit(): void {
    this.year = new Date().getFullYear();
    //this.toastr.info("Loading ...");
    this.dtOptions = {
      //responsive: true,
      className: "table-history",
      responsive: {
        details: {
          renderer: Responsive.renderer.listHiddenNodes()
        }
      },
      dom: 'Blfrtip',
      columnDefs: [
        { width: '300px', targets: 5 },
        { "orderable": false, targets: 7 }
      ],
      fixedColumns: true,
      order: [[0, 'desc']],
      lengthMenu: [
        [10, 25, 50, -1],
        ['10 rows', '25 rows', '50 rows', 'Show all']
      ]
    };
    if (this._roleService.isSiteManager) {
      this.dtOptions.buttons = [
        {
          extend: 'pdf',
          text: 'Export to PDF'
        }
      ]
    }
    else {
      this.dtOptions.buttons = []
    };

    this.http.post<any>(`${this.baseUrl}api/calls/ClearSession`, null).subscribe(result => {
      this.http.get<any[]>(`${this.baseUrl}api/Calls/GetCalls`).subscribe(result => {
        this._setFilters(result);
        this.isReady = true;
        this.dtTrigger.next();

        this.toastr.success("Finished!");

      }, error => console.error(error));
    });

    this.statusDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 9,
      allowSearchFilter: false
    };

    this.callTypeDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 9,
      allowSearchFilter: false
    };

    this.sitesDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 9,
      allowSearchFilter: false
    };

    this.customersDropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 9,
      allowSearchFilter: false
    };

  }

  _setFilters(_calls: any) {
    this.calls = _calls;
    //this.calls = this.calls.filter(x => {
    //  return x.reported.includes(this.year);
    //})
    this.statuses = this.calls.map(x => {
      return x.status;
    })

    this.statuses = this.statuses.filter(this.onlyUnique).sort();
    this.statuses = this.statuses.filter(x => {
      return x != null;
    })
    this.calltypes = this.calls.map(x => {
      return x.callType;
    })

    this.calltypes = this.calltypes.filter(this.onlyUnique).sort();
    this.calltypes = this.calltypes.filter(x => {
      return x != null;
    })
    this._sites = this.calls.map(x => {
      return x.site;
    })

    this._sites = this._sites.filter(this.onlyUnique).sort();
    this._sites = this._sites.filter(x => {
      return x != null;
    })

    this._customers = this.calls.map(x => {
      return x.customer;
    })

    this._customers = this._customers.filter(this.onlyUnique).sort();
    this._customers = this._customers.filter(x => {
      return x != null;
    })
  }

  ngAfterViewInit() { }

  onClearDateFilter() {
    this.fromDate = null;
    this.toDate = null;
    this.year = 0;
    this.month = 0;
    this.isRangeSelected = false;
    this.isPeriodFilterButton1Selected = false;
    this.isPeriodFilterButton2Selected = false;
    this.isPeriodFilterButton3Selected = true;
    this.isPeriodFilterButton4Selected = false;
    // if (this.fromDate != null && this.toDate != null) {  // this wasn't doing anything!!
    // }
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")
    })
  }

  onClearSession() {
    this.toastr.info("Updating started ...");

    this.http.post<any>(`${this.baseUrl}api/calls/ClearSession`, null).subscribe(result => {

      this.filterCallHistory().subscribe(result => {
        this.calls = result;
        this.rerender();
        this.toastr.success("Updated!");
      })
    });
  }

  //---start calendar---
  onDateSelection(date: NgbDate) {
    if (this.fromDate != null && this.toDate != null) {
      this.year = date.year;
      this.month = date.month;
      this.isPeriodFilterButton1Selected = false;
      this.isPeriodFilterButton2Selected = false;
      this.isPeriodFilterButton3Selected = false;
      this.isPeriodFilterButton4Selected = false;
      this.toastr.info("Filtering...")

      this.isRangeSelected = true;
      this.filterCallHistory().subscribe(result => {
        this._setFilters(result);
        this.rerender();
        this.toastr.success("Data is filtered!")

      })

    }
  }
  //---end calendar---

  filterCallHistory() {
    this.filterData = new FilterData();
    this.filterData.email = this._tokenservice.userEmail;
    this.filterData.sites = this.selectedSites;
    this.filterData.callTypes = this.selectedCallTypes;
    this.filterData.statuses = this.selectedStatuses;
    this.filterData.customers = this.selectedCustomers;
    this.filterData.selectedDate = this.dateYearMonth;
    this.filterData.month = this.month;
    this.filterData.year = this.year;
    if (this.isRangeSelected) {
      this.filterData.fromDay = this.fromDate.day;
      this.filterData.fromMonth = this.fromDate.month;
      this.filterData.fromYear = this.fromDate.year;
      this.filterData.toDay = this.toDate.day;
      this.filterData.toMonth = this.toDate.month;
      this.filterData.toYear = this.toDate.year;
      this.filterData.isRange = true;
    }
    return this.http.post<any>(`${this.baseUrl}api/calls/GetFilteredCallHistory`, this.filterData)
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next();
    });
  }

  changePeriodFilterButton1() {
    this.isPeriodFilterButton1Selected = true;
    this.isPeriodFilterButton2Selected = false;
    this.isPeriodFilterButton3Selected = false;
    this.isPeriodFilterButton4Selected = false;
  }
  changePeriodFilterButton2() {
    this.isPeriodFilterButton1Selected = false;
    this.isPeriodFilterButton2Selected = true;
    this.isPeriodFilterButton3Selected = false;
    this.isPeriodFilterButton4Selected = false;

  }
  changePeriodFilterButton3() {
    this.isPeriodFilterButton1Selected = false;
    this.isPeriodFilterButton2Selected = false;
    this.isPeriodFilterButton3Selected = true;
    this.isPeriodFilterButton4Selected = false;

  }
  changePeriodFilterButton4() {
    this.isPeriodFilterButton1Selected = false;
    this.isPeriodFilterButton2Selected = false;
    this.isPeriodFilterButton3Selected = false;
    this.isPeriodFilterButton4Selected = true;
  }

  filterByThisMonth() {
    this.toastr.info("Filtering...")

    this.fromDate = null;
    this.toDate = null;
    this.isRangeSelected = false;
    var thisMonth = this.calendar.getToday().month;
    var thisYear = this.calendar.getToday().year;
    this.month = thisMonth;
    this.year = thisYear;
    this.filterCallHistory().subscribe(result => {
      this._setFilters(result);
      this.rerender();
      this.toastr.success("Data is filtered!")

    })

  }

  filterByLastMonth() {
    this.toastr.info("Filtering...")

    this.fromDate = null;
    this.toDate = null;
    this.isRangeSelected = false;

    var lastMonth = this.calendar.getToday().month - 1;
    var thisYear = this.calendar.getToday().year;
    if (lastMonth == 0) {
      lastMonth = 12;
      thisYear = thisYear - 1;
    }
    this.month = lastMonth;
    this.year = thisYear;
    this.filterCallHistory().subscribe(result => {
      this._setFilters(result);
      this.rerender();
      this.toastr.success("Data is filtered!")
    })
  }

  filterByThisYear() {
    this.toastr.info("Filtering...")

    this.fromDate = null;
    this.toDate = null;
    this.isRangeSelected = false;
    var thisYear = this.calendar.getToday().year;
    this.month = 0;
    this.year = thisYear;
    this.filterCallHistory().subscribe(result => {
      this._setFilters(result);
      this.rerender();
      this.toastr.success("Data is filtered!")

    })

  }

  filterByLastYear() {
    this.toastr.info("Filtering...")

    this.fromDate = null;
    this.toDate = null;
    this.isRangeSelected = false;
    var lastYear = this.calendar.getToday().year - 1;
    this.month = 0;
    this.year = lastYear;
    this.filterCallHistory().subscribe(result => {
      this._setFilters(result);
      this.rerender();
      this.toastr.success("Data is filtered!")
    })

  }

  onKey(event: any) {
    this.values += event.target.value;
  }

  onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
  }

  getCallsAll() {
    return this.http.get<any[]>(this.baseUrl + `api/Calls/GetCalls`);
  }
  //------------Start Status-----------
  onItemSelectStatus(item: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }

  onSelectAllStatus(items: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })

  }

  onItemDeSelectStatus(items: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }
  //------------End Status-----------
  //------------Start Calltype-----------

  onItemSelectCalltype(item: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }

  onSelectAllCalltype(items: any) {
    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }

  onItemDeSelectCalltype(items: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }
  //------------END Calltype-----------
  //------------Start SITES-----------

  onItemSelectSites(item: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }

  onSelectAllSites(items: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }

  onItemDeSelectSites(items: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }
  //------------END SITES-----------
  //------------Start Customers-----------

  onItemSelectCustomers(item: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }

  onSelectAllCustomers(items: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }

  onItemDeSelectCustomers(items: any) {
    this.toastr.info("Filtering...")

    this.filterCallHistory().subscribe(result => {
      this.calls = result;
      this.rerender();
      this.toastr.success("Data is filtered!")

    })
  }
  //------------END SITES-----------

  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  navigate() {
    alert("navigate")
  }

  details(call: Calls) {
    alert(call.callNumber);
  }

}

export interface Calls {
  caller: string;
  site: string;
  callType: string;
  solution: string;
  description: string;
  callNumber: number;
  status: number;
  callerTelephone: string;
  reported: Date;
  sites: Sites;
}

interface Sites {
  siteName: string;
}

